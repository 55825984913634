.form_general {
    width: 100%;

    p {
        width: 100%;
        padding: 10px 0;
        color: rgb(92, 94, 97);
        font-size: 16px;
        text-align: center;
    }

    .cont_form {
        width: 100%;
        background-color: rgb(255, 255, 255);

        .form {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            
        }

        .form_column{
            flex: 1;
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        .no_flex{
            width: 50%;
            flex: none;
        }


        label{
            width: 100%;
            margin-bottom: 2px;
            color: rgb(92, 94, 97);
            font-size: 12px;
            
        }

        input{
            width: 100%;
            height: 28px;
            background-color: rgb(255, 255, 255);
            border: solid 1px rgb(200, 200, 200);
            border-radius: 6px;

        }

        select {
            width: 100%;
            height: 28px;
            background-color: rgb(255, 255, 255);
            border: solid 1px rgb(200, 200, 200);
            border-radius: 6px;
            cursor: pointer;
        }

        .calendar {
            width: 100%;
            height: 28px;
            background-color: rgb(255, 255, 255);

            svg {
                width: 18px;
                position: absolute;
                margin-left: -26px;
                cursor: pointer;
            }

         
        }
    }
}

.formshipperconsignee{
    width: 100%;
}
