.dropzone {
  width: calc(100% - 2px);
  display: flex;
  position: relative;
  cursor: pointer;

  &.dropzone--single {
    height: 100px;

    .dropzone__img {
      margin: 0;
      height: 100%;
      width: 100%;
      text-align: center;

      img {
        padding: 20px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .dropzone__input:hover {
      background-image: linear-gradient(
        -45deg,
        var(--colorBorder) 25%,
        transparent 25%,
        transparent 50%,
        var(--colorBorder) 50%,
        var(--colorBorder) 75%,
        transparent 75%,
        transparent
      );

      background-size: 30px 30px;
      animation: striped 2s linear infinite;

      @keyframes striped {
        from {
          background-position: 0 0;
        }

        to {
          background-position: 60px 30px;
        }
      }
    }
  }

  &.dropzone--custom-height {
    min-height: 300px;
    height: auto;

    .dropzone__img {
      max-width: 100%;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &.dropzone--multiple {
    min-height: 400px;

    .dropzone__input {
      min-height: 400px;
    }
  }
}

.dropzone__input {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  position: absolute !important;
  cursor: pointer;
}

.dropzone__drop-here {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 12px;
    font-size: 20px;
    color: var(--color-additional);
  }

  p {
    color: var(--color-additional);
  }
}

.dropzone__imgs-wrapper {
  padding: 30px 20px;
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;
}

.dropzone__img {
  margin-bottom: 30px;
  width: calc(16.6667% - 30px);
  height: 150px;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  outline: 1px solid var(--colorBorder);

  .dropzone__h3 {
    display: block;
    width: 100%;
    margin: 8px 12px;
    padding: 8px 4px;
    font-size: 12px;
    line-height: 1.1;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    position: relative;

    span {
      margin: auto 0;
      padding: 6px 4px;
      position: absolute;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      z-index: 20;
    }

    @media screen and (max-width: 576px) {
      min-height: 22px;

      span {
        padding: 12px 4px;
        font-size: 14px;
      }
    }
  }

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    cursor: default;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0px;
    z-index: 1;
  }

  &:hover {
    &:before {
      background-color: rgba(25, 25, 25, 0.6);
    }

    .dropzone__img-delete {
      opacity: 1 !important;
    }

    .dropzone__img-name {
      opacity: 1 !important;
    }

    .dropzone__h3 {
      color: rgb(255, 255, 255);
    }
  }
}

.new_container .new_features p.dropzone__img-name,
.dropzone__img-name {
  color: white !important;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;
  width: 100%;
  height: auto !important;
  line-height: 12px;
  margin: 0;
  padding: 30px 0;
  top: 10px;
}

.dropzone__img-delete {
  position: absolute;
  transition: all 0.3s;
  right: 4px;
  top: 4px;
  cursor: pointer;
  background: transparent;
  color: white;
  font-size: 8px;
  line-height: 1;
  text-transform: uppercase;
  padding: 4px 7px 2px;
  border: 1px solid white;
  border-radius: 6px;
  z-index: 22;
  opacity: 0;

  @media screen and (max-width: 576px) {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.6);

  .load__icon-wrap {
    margin: auto;
  }

  .load__icon {
    animation: linear loading 2s infinite;
    width: 32px;
    height: 32px;
  }
}

.dropzone__alert {
  .alert__input {
    top: 100px;
    width: max-content;
    white-space: normal;
    z-index: 10;
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}
