.back_form {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  z-index: 101;
  background: transparent;

  &._bkg_white {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &._bkg_black {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

._release,
.tool_bar {
  ._packages_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 64px;

    p {
      margin: 8px 6px;
      padding: 6px 8px;
      min-width: 200px;
      border: 1px solid rgba(68, 47, 47, 0.3);
      border-radius: 12px;

      svg {
        float: right;
        cursor: pointer;
      }
    }
  }
}

.rowMenu {
  max-width: 0;

  .back_form {
    position: fixed !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
    z-index: 101;
    background: transparent;
    cursor: auto;

    &._bkg_black {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.menu {
  position: fixed;
  display: block;
  max-width: 220px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  background: var(--bkgColor);
  z-index: 102;

  ul {
    margin: 12px 0;
    padding: 0;
    list-style: none;

    hr {
      width: 100%;
      height: 1px;
      margin: 4px 0;
      border: none;
      background-color: var(--textGray);
    }

    li {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0;
      padding: 0.2rem 0.3rem;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: var(--colorHover);
      }

      svg {
        width: 20px;
        min-width: 20px;
        color: var(--textColor);
      }

      span {
        margin-left: 8px;
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis !important;
        color: var(--textColor);

        @media screen and (max-width: 576px) {
          font-size: 1.2rem;
        }
      }

      &.unable {
        cursor: not-allowed;

        span {
          color: rgb(170, 170, 170);
        }

        svg {
          color: rgb(170, 170, 170);
        }
      }

      @media screen and (max-width: 576px) {
        padding: 0.5rem 0.9rem 0.5rem 0.1rem;

        svg {
          width: 26px;
          height: 26px;
          margin-left: 6px;
        }
      }
    }
  }
}

.load_common {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: auto;

  &._in_full {
    position: absolute;
  }

  .load__icon-wrap {
    margin: auto;
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.history {
  position: fixed;
  display: block;
  width: auto;
  max-width: 100vw;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  background: var(--bkgColor);
  z-index: 91;
  cursor: auto;

  h3,
  h4 {
    margin: 6px 8px 14px !important;

    svg {
      width: 26px;
      float: right;
      cursor: pointer;
    }
  }

  div {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }
  }

  .table {
    border-radius: 0;

    tr {
      &:hover {
        background-color: inherit;
        color: rgb(109, 106, 106);
      }
    }

    th,
    td {
      padding: 0 12px !important;
      cursor: auto;
    }

    td {
      &:nth-last-child(2) {
        border-right: 1px solid var(--bkgBlue);
      }

      &:last-child {
        padding: 0 12px !important;
      }
    }

    .table_row {
      th,
      td {
        color: var(--textColor) !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    left: 2% !important;
    width: 96%;
  }
}

.content_modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.modal_img {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
  cursor: auto;
  z-index: 110;

  svg {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 24px;
    height: 24px;
    padding: 0;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  img {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    object-fit: scale-down;
    margin: 0;
  }

  .modal_bkg-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    cursor: auto;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @media screen and (max-width: 576px) {
    svg {
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
    }
  }
}

._title_win {
  position: relative;
  margin: 0 0 1.6rem !important;
  padding: 0 1.5rem 0 0 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-align: left !important;
  white-space: pre-wrap !important;
  cursor: move !important;

  svg {
    position: absolute;
    top: -0.2rem;
    right: -0.4rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin: 0 0 0 24px !important;
    padding: 0 !important;
    float: unset !important;
    cursor: pointer;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.3rem !important;
    padding: 0 2rem 0 0 !important;

    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

.win_modal {
  position: fixed;
  display: block;
  max-width: 550px;
  padding: 0.5rem 0.9rem;
  border-radius: 8px;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  background: var(--bkgColor);
  z-index: 100;
  cursor: auto;

  &._movil {
    width: 100% !important;
  }

  &._small {
    max-width: 250px;

    .btn {
      margin-top: 1.3rem;
    }

    @media screen and (max-width: 576px) {
      max-width: 80%;
      left: 10% !important;
    }
  }

  &._overflow {
    overflow: visible;
  }

  &._for_color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 214px;

    button {
      position: relative;
      width: 100%;
      margin: 6px 0;
      border: none;
      background: none;
      text-align: left;
      padding-left: 32px;
      font-size: 1rem !important;
      color: var(--textList);

      span {
        position: absolute;
        top: 0;
        left: 6px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid var(--textList);
      }
    }
  }

  h3,
  h4 {
    margin-bottom: 28px;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    text-align: left;

    svg {
      width: 26px;
      float: right;
      cursor: pointer;
      margin-left: 30px;
    }

    span {
      font-size: 1rem;
    }

    &._error {
      position: relative;
      width: 100%;
      margin: 8px 0;
      padding-bottom: 0;
      padding-right: 25px;
      font-size: 14px;
      line-height: 1.2;
      text-align: left;
      color: rgb(255, 32, 32);
      white-space: pre-line;

      svg {
        position: absolute;
        top: -8px;
        right: -6px;
        width: 20px;
        margin: 0 !important;
        color: rgb(255, 32, 32);
        cursor: auto;
      }
    }
  }

  .calendar_in_modal {
    width: 100%;
    position: relative;

    svg {
      position: absolute;
      top: 4px;
      right: 2px;
      width: 18px;
      height: 18px;
      pointer-events: none;
      fill: var(--textColor);
    }
  }

  ._in_column {
    max-width: 100%;
    max-height: 68vh;
    display: block;
    text-align: left;

    &._over_margin {
      max-width: calc(100% + 28px);
      padding: 0.5rem 1rem 0.9rem;
      margin-top: -1rem;
      margin-bottom: 0.5rem;
      margin-left: -14px;
      margin-right: -14px;
      overflow-y: scroll;
      border-top: 1px solid rgba(var(--textList), 0.3);
      border-bottom: 1px solid rgba(var(--textList), 0.3);
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid rgb(100, 100, 100);
      background-color: rgb(100, 100, 100);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(230, 230, 230);
    }

    &._margin_right {
      margin-right: 12px;
    }

    .l_check {
      display: flex;
      margin: 8px 14px;
      cursor: pointer;
      align-items: center;
      color: var(--textColor);

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        pointer-events: none;
      }
    }

    input[type="date"],
    input[type="time"] {
      min-width: 120px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }

    textarea,
    input {
      width: 100%;
      padding: 4px 6px !important;
    }

    textarea,
    input[type="text"] {
      min-width: 200px;
    }

    textarea {
      resize: none;
    }

    textarea,
    input,
    select {
      width: 100%;
      padding: 4px 6px;
      font-size: 0.8rem;
      border: 1px solid rgba(112, 112, 112, 0.8);
      background-color: var(--bkgColor);
      color: var(--textColor);
      border-radius: 4px;

      @media screen and (max-width: 576px) {
        padding: 6px !important;
        font-size: 1rem;
      }
    }

    .__titulo {
      width: 98%;
      margin-top: 12px;
      margin-bottom: 2px;
      color: var(--textColor);

      svg {
        width: 24px;
        height: 24px;
        padding: 2px;
      }

      @media screen and (max-width: 576px) {
        margin-top: 16px;
      }
    }

    .field_icon {
      width: 100%;
      max-width: 150px;
      height: min-content;
      margin: 0;
      padding: 0;
      display: flex;
      color: var(--textColor);
      justify-content: flex-end;
      align-items: center;

      svg {
        width: 18px;
        position: absolute;
        margin-right: 7px;
        pointer-events: none;
        background-color: rgb(255, 255, 255);
      }

      &._min_110 {
        width: 110px;

        input {
          min-width: 100px !important;
          padding: 4px 6px !important;
          border: 1px solid rgba(68, 47, 47, 0.6);
          border-radius: 4px;
          color: var(--textColor);
        }

        .rc-time-picker {
          width: 100%;

          .rc-time-picker-input {
            margin-left: 0;
            height: min-content;
            font-size: 12px;
          }
        }
      }
    }

    ._link_file {
      display: block;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;

      svg {
        width: 16px;
        height: 16px;
      }

      @media screen and (max-width: 576px) {
        font-size: 16px;
        padding: 4px 0;
      }
    }

    p {
      white-space: normal;

      &._info {
        margin-bottom: 12px;
      }

      &._msg_p {
        margin: 28px 0 18px;
        padding: 0;
        font-size: 0.9rem;
        font-weight: normal;
        text-align: left;
        white-space: pre-wrap;

        i {
          font-size: 0.8rem;
          white-space: pre-wrap;
        }
      }

      &._metodo_pago {
        display: block;
        margin: 0 0 6px;
        padding: 0;
        font-size: 0.8rem;
        font-weight: normal;
        text-align: left;
      }
    }

    .input {
      position: relative;

      input {
        padding: 0.2rem 22px 0.2rem 0.6rem !important;

        &[type="text"] {
          min-width: auto;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0;
      }

      @media screen and (max-width: 576px) {
        input {
          padding: 0.4rem 1.8rem 0.4rem 0.6rem !important;
        }

        svg {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }

    ._bar_code {
      display: block;

      svg {
        width: 100%;
        height: 90px;
        margin: 0;

        g {
          fill: var(--textColor) !important;

          text {
            font-size: 18px !important;
            font-weight: bold !important;
            font-family: "Roboto", sans-serif !important;
          }
        }
      }
    }

    .calendar {
      position: relative;
      width: calc(100% - 16px);
      margin: 0 8px;

      svg {
        position: absolute;
        top: 1px;
        right: 0;
        width: 18px;
        pointer-events: none;
      }

      input {
        padding: 6px 8px;
        font-size: 0.9rem;
      }
    }
  }

  ._in_row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin: 12px 8px;
    align-items: flex-end;

    &._w_100,
    input[type="text"] {
      min-width: 100px;
    }

    p {
      padding-right: 120px;

      span {
        margin: 0 12px 0 12px;
      }
    }

    .input {
      /*  position: absolute */
      right: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      /*  width: 200px */

      input {
        /*  width: 100px */
        padding: 4px 6px;
        margin-left: 8px;
        /*  padding-right: 22px !important */
      }

      svg {
        position: absolute;
        right: 0;
        padding: 4px;
        cursor: pointer;
        transition: all 200ms;
        color: var(--textColor) !important;

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &:has(> ._scroll) {
    padding: 0;

    h3 {
      margin: 12px 0 4px !important;
      padding: 0 12px !important;

      svg {
        right: 0.4rem;
      }
    }

    ._line {
      width: 100%;
      margin-left: 0;
    }
  }

  ._scroll {
    margin: 0;
    padding: 8px 4px 12px 8px;
    max-height: calc(100vh - 235px);
    overflow-y: scroll;
    overflow-x: hidden;
    border-top: 1px solid var(--colorThumb);
    border-bottom: 1px solid var(--colorThumb);

    p {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      position: relative;
      margin: 0.4rem 0 0.4rem;
      font-size: 0.8rem;
      cursor: pointer;

      span {
        margin: 2px 0 2px 8px;
        padding: 0 12px;
        border-radius: 8px;
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-weight: bold;
        text-shadow: 0 0 2px #1e1e1e, 0 0 1px #1e1e1e;
      }

      svg {
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 8px;
        cursor: pointer;

        &._first_svg {
          width: 22px;
          height: 22px;
          padding-left: 0;
          margin-left: -4px;
          margin-right: 0;
          cursor: auto;
        }
      }

      input {
        margin-right: 6px;
        pointer-events: none;
      }

      &._btn {
        width: max-content;
        display: block;
        overflow: hidden;
        margin: 0.5rem auto 1rem;
        padding: 0.2rem 1rem;
        font-size: 0.8rem;
        line-height: 1.6;
        text-align: center;
        white-space: nowrap;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s;
        color: var(--textColor);
        border: 1px solid var(--textColor);

        svg {
          width: 2rem;
          height: 2rem;
          margin: 0 0.2rem;
        }

        &:active {
          transform: scale(0.95);
        }

        @media screen and (max-width: 576px) {
          font-size: 1rem;
        }
      }

      &._nowrap {
        flex-flow: row nowrap;
        overflow: hidden;

        b {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &._nowrap + i {
        display: block;
        width: max-content;
        padding: 0 6px 0 4px;
        margin: -0.4rem 0 0 1.4rem;
        border-radius: 4px;
        font-size: 0.6rem;
        color: var(--textColor);
        border: 1px solid var(--textColor);
      }

      i {
        display: inline-block;
        padding: 0 6px 0 4px;
        margin: 0 0.5rem 0 0.1rem;
        border-radius: 4px;
        font-size: 0.6rem;
        color: var(--textColor);
        border: 1px solid var(--textColor);
      }

      @media screen and (max-width: 576px) {
        margin: 0.6rem 0 0.4rem;
        font-size: 1.1rem;

        &._nowrap + i {
          margin-bottom: 0.6rem;
        }
      }
    }

    ._packages {
      margin-left: 38px;

      p {
        margin: 0;

        &::before {
          background: none;
        }

        input {
          margin-right: 4px;
        }
      }
    }

    .btn_lazy_load {
      width: calc(100% - 24px);
      margin: 18px 14px 6px;
      padding: 6px;
      font-size: 0.9rem;
      transform: none;
      border-radius: 16px;
      color: var(--textColor);
      border: 1px solid var(--textColor);
      background: var(--bkgColor);

      svg {
        margin-top: 0;
        width: 1rem;
        height: 1rem;
        transform: scale(1.5);
      }
    }

    .h4_lazy_load {
      width: 100%;
      text-align: center;
      margin: 1.2rem 0 0.3rem;
      font-size: 1rem;
      white-space: pre-line;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }
  }

  .btn {
    margin: 6px auto 0;
    float: right;
  }

  .cont_botones {
    margin-top: 24px;

    button {
      position: relative;
      width: 46%;
      margin: 0;

      &._all {
        float: left;
      }

      &._des {
        opacity: 0.5;
        cursor: not-allowed;
      }

      @media screen and (max-width: 576px) {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  ._zone {
    padding: 2px 6px 3px;
    margin-left: 6px;
    font-size: 11px;
    line-height: 1;
    border-radius: 4px;
    color: var(--textColor);
    border: 1px solid var(--textColor);
  }

  label,
  textarea,
  input {
    &::placeholder {
      color: var(--textColor);
      opacity: 0.5;
    }
  }

  input[type="file"] {
    display: none;
  }

  .name_img {
    margin: 2px 0;
    display: block;
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--textBlue);
  }

  .label_icon {
    position: relative;
    width: 38px;
    height: 38px;
    margin: 0;
    cursor: pointer;
    z-index: 20;
    color: var(--textColor) !important;

    svg {
      margin: 6px auto !important;
      width: 38px !important;
      height: 38px !important;
      fill: var(--textBlue);
      color: var(--textBlue);

      &.on_refresh {
        animation: refresh 1s infinite linear;
      }
    }
  }

  &._delivery {
    padding: 8px 14px;
    overflow: hidden;

    input,
    textarea,
    select {
      padding: 0.2rem 0.6rem !important;
      font-size: 0.8rem !important;

      @media screen and (max-width: 576px) {
        padding: 0.4rem 0.6rem !important;
        font-size: 1rem !important;
      }
    }

    input[type="text"] {
      padding: 0.2rem 1.4rem 0.2rem 0.6rem !important;
      font-size: 0.8rem !important;

      @media screen and (max-width: 576px) {
        padding: 0.4rem 1.4rem 0.4rem 0.6rem !important;
        font-size: 1rem !important;
      }
    }

    .close_option {
      position: absolute;
      top: 12px;
      right: 0;
      width: 42px;
      height: 42px;
      padding: 8px;
      cursor: pointer;
      color: var(--textColor) !important;
    }

    ._cont_entrega {
      margin-top: 0;
      padding-top: 0;

      .cont_loading {
        width: 100%;

        svg {
          display: block;
          width: 60px;
          height: 60px;
          margin: 32px auto;
        }
      }

      ._precio {
        width: 100%;
        font-size: 1.2rem;
        line-height: 1;
        margin-top: 0;

        span {
          display: inline-block;
          padding: 0 0 8px;
          margin: 8px 12px 0;
          font-size: 1rem;
          line-height: 1;
          font-weight: normal;
        }

        hr {
          margin: 8px 0 0;
        }

        &._msg {
          margin: 28px 0 18px;
          font-size: 0.9rem;
          font-style: italic;
          font-weight: normal;
          text-align: left;
          white-space: pre-line;
        }
      }

      i {
        display: inline-block;
        margin: 6px 0 12px;
        font-size: 0.8rem;
        color: var(--textColor);
      }
    }

    ._titulo {
      margin-right: -8px;
      margin-bottom: 12px;
      font-size: 1rem;
      white-space: pre-line;
    }

    ._precio {
      margin: 8px 0 12px;
      font-size: 1.1rem;
    }

    ._p_arrow {
      padding-left: 18px;
      font-size: 0.75rem;
      color: rgb(22, 94, 176);

      svg {
        margin-top: -6px;
        width: 16px;
        height: 16px;
      }

      span {
        cursor: pointer;
      }

      @media screen and (max-width: 576px) {
        font-size: 0.8rem;
      }
    }

    ._radio_group {
      display: flex;
      flex-direction: column;
      padding-top: 6px;
      padding-bottom: 8px;

      label {
        position: relative;
        display: inline-block;
        width: min-content;
        font-size: 0.9rem;
        padding: 4px 0;
        padding-left: 42px;
        margin-left: 4px;
        color: var(--textColor) !important;
        cursor: pointer;
      }

      input[type="radio"] {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0 12px;
        cursor: pointer;
      }
    }

    h5 span {
      display: block;
      width: max-content;
      padding: 6px 8px;
      margin: 8px 0 18px;
      border-radius: 6px;
      font-size: 1rem;
      font-weight: bolder;
    }
  }

  ._line {
    width: calc(100% + 16px);
    margin: 0;
    margin-left: -8px;
    background-color: #1d3757;
    opacity: 0.3;
  }

  &:has(> ._to_scroll) {
    padding: 0;

    h3 {
      margin: 12px 0 4px !important;
      padding: 0 12px !important;
    }
  }

  ._to_scroll {
    width: 100%;
    max-height: calc(100vh - 190px);
    padding: 12px 12px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid var(--colorThumb);
    border-bottom: 1px solid var(--colorThumb);

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }

    ._title_win {
      width: 100%;
    }

    ._detalle {
      display: block;
      width: 90%;
      font-size: 0.9rem;

      b {
        font-size: 1rem;
      }
    }
  }

  .cont_btns_caja {
    padding-bottom: 12px;
  }

  .search_bar {
    max-width: calc(100% - 4px);

    input {
      border-bottom: none !important;
    }
  }

  @media screen and (max-width: 576px) {
    max-width: calc(100vw - 24px);

    label,
    textarea,
    select,
    input {
      font-size: 1rem;
    }

    .input ._float_list li {
      min-height: 32px;
      padding: 8px 2p;
      font-size: 14px !important;
    }

    ._scroll {
      max-height: calc(100vh - 245px);

      p {
        input[type="checkbox"] {
          width: 20px;
          height: 20px;

          &:checked {
            background-color: blue;
          }
        }
      }
    }
  }
}

.btn_tool {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  float: right;

  .icon_tool {
    transition: all 200ms;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }

    svg {
      width: 24px;
      padding: 4px;
    }
  }

  .back_form {
    position: fixed !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
    z-index: 101;
    background: transparent;
  }

  @media screen and (max-width: 576px) {
    width: 32px;
    height: 32px;
    margin: -8px 4px 0;

    .icon_tool {
      svg {
        width: 32px !important;
        height: 32px !important;
      }
    }
  }
}

._button {
  position: relative;
  display: block;
  float: right;
  margin: 18px 0 !important;
  padding: 0.2rem 2.7rem 0.1rem 0.5rem !important;
  font-size: 1rem !important;
  line-height: 1.8 !important;
  border-radius: 6px !important;
  cursor: pointer;
  color: var(--textColor);
  border: 1px solid var(--textColor);

  svg {
    position: absolute;
    top: 3px;
    right: 0;
    width: 1.7rem !important;
    height: 1.7rem !important;
    margin: 0 6px !important;
  }

  &._width_static {
    width: 100%;
  }

  &._with_margin {
    margin: 1rem !important;
  }
}

.new_agent {
  position: absolute;
  width: 500px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: #232329;
  background: var(--bkgColor);
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  cursor: default;
  z-index: 98;

  &:has(> ._to_scroll),
  &:has(> ._row_permission) {
    padding: 0;

    h3 {
      margin: 12px 0 4px !important;
      padding: 0 12px !important;

      svg {
        right: 0.4rem;
      }
    }
  }

  ._to_scroll {
    width: 100%;
    max-height: calc(100vh - 180px);
    padding: 12px 12px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid var(--colorThumb);
    border-bottom: 1px solid var(--colorThumb);

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }
  }

  &:has(> ._row_permission) {
    padding: 0;

    h3 {
      margin: 12px 0 4px !important;
      padding: 0 12px !important;
    }
  }

  ._in_row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    &._to_left {
      justify-content: left;
    }

    &._to_right {
      justify-content: right;
    }
  }

  ._in_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    padding: 4px 8px;

    &:last-child {
      margin-right: 0;
    }

    &._two_columns {
      position: absolute;
      align-items: flex-start;
      width: 80%;
    }
  }

  h3 {
    width: 100%;
    margin: 12px 0 4px !important;
    font-size: 16px !important;

    svg {
      padding: 0 !important;
      float: right;
      cursor: pointer;
    }
  }

  ._error {
    width: 100%;
    margin: 16px 6px;
    padding-left: 35px;
    font-size: 14px;
    color: rgb(255, 32, 32);
    white-space: pre-wrap;

    svg {
      width: 20px;
      margin-left: -35px;
      margin-right: 12px;
      float: none;
      cursor: auto;
    }
  }

  label {
    width: 90%;
    margin: 12px 0 0;
    padding-left: 8px;
    color: rgb(100, 103, 119);
    font-size: 12px;

    &.cont_check {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin: 6px 8px;
      padding: 2px 12px 2px 8px;
      width: min-content;
      font-size: 1rem;
      white-space: nowrap;
      border-radius: 6px;
      cursor: pointer;
      transition: all 100ms;
      border: 1px solid var(--colorInput);
      background-color: var(--bkgInbox);
      color: var(--textColor);

      &:active {
        transform: scale(0.95);
        background-color: color-mix(in srgb, var(--bkgInbox), black 10%);
      }

      @media screen and (max-width: 576px) {
        margin: 8px 8px;
        padding: 4px 14px 4px 8px;
        font-size: 1.2rem !important;
      }
    }
  }

  input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin: 0 8px 0 0;
    pointer-events: none;

    @media screen and (max-width: 576px) {
      width: 18px;
      height: 18px;
    }
  }

  select,
  textarea,
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="text"] {
    width: 100%;
    min-width: 180px !important;
    padding: 8px 8px 6px;
    font-size: 12px;
    border-radius: 4px;
    outline: none;
    color: var(--textColor);
    background: var(--bkgInbox);
    border: 1px solid var(--colorInput);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--textColor) !important;
      -webkit-box-shadow: 0 0 0px 50px var(--bkgInbox) inset;
    }

    &::placeholder {
      text-transform: lowercase;
    }
  }

  textarea {
    min-height: 60px !important;
    resize: none;
  }

  button {
    margin-top: 18px;
  }

  .input {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    width: 300px;
  }
}

._toglle_pass {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    position: absolute;
    right: 4px;
    padding: 2px;
    cursor: pointer;
    color: rgb(149, 136, 134);
  }
}

._row_permission {
  position: relative;
  display: flex;
  padding-left: 8px;
  flex-flow: column;
  width: 100%;
  max-height: calc(100vh - 180px);
  border-top: 1px solid var(--colorThumb);
  border-bottom: 1px solid var(--colorThumb);
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 98;

  h4 {
    font-size: 1rem;
    margin-left: 1rem;

    &._hr {
      display: block;
      width: 100%;
      margin: 0.6rem 0 0.3rem;
      border-top: 1px solid var(--colorThumb);

      &._hr:first-child {
        margin: 0.1rem 0 0.3rem;
        border-top: none;
      }

      span {
        display: block;
        font-size: 1rem;
        padding: 8px 12px 0;
        color: var(--textColor);
      }
    }
  }

  ._bkg_black {
    position: absolute;
    width: 100%;
    height: 300%;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: var(--bkgColorTrans);
  }

  ._input_in_permission {
    position: relative;
    width: 300px;
    margin-left: 10%;

    @media screen and (max-width: 576px) {
      width: 80%;
    }
  }

  ._marco_resalta {
    border: 2px solid rgb(255, 18, 18) !important;
  }

  ._btns_in_row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 300px;
    margin-left: 10%;
    z-index: 100;

    button {
      margin: 8px 8px 12px;
      font-size: 12px;
      color: var(--textColor);
      border: 1px solid var(--textColor);

      &:before {
        opacity: 0.5;
      }
    }

    @media screen and (max-width: 576px) {
      width: 80%;
    }
  }

  &._not_scroll {
    margin: 0 auto 1rem !important;
    overflow-y: auto;
    width: max-content;
    max-width: max-content;
    max-height: min-content !important;
    border-top: none;
    border-bottom: none;

    h4._hr {
      width: 100%;

      span {
        padding-top: 2rem;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bkgColor);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid var(--colorThumb);
    background-color: var(--colorThumb);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--textColor);
  }
}

._btn_a {
  padding: 4px 8px;
  margin-left: 12px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid var(--colorInput);
  background-color: var(--bkgInbox);
  color: var(--colorLinck);

  &:active {
    background-color: color-mix(in srgb, var(--bkgInbox), black 10%);
  }

  @media screen and (max-width: 576px) {
    margin-left: 2px;
  }
}

.react-datepicker {
  background-color: var(--bkgColorBody);
  color: var(--textColor);
}

.react-datepicker__header {
  background-color: var(--bkgColor);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--textColor);
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: var(--bkgColorBody);
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--bkgColor);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgb(33, 107, 165);
  color: #fff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: rgba(33, 108, 165, 0.6);
  color: #ffffff !important;
}
