.prealert {
    width: 98%;
    margin: 0 10px;
    padding: 20px;
    background-color: rgb(255, 255, 255);

    .title {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: row;

        h2 {
            width: 90%;
        }

        .boton_crear {
            display: flex;
            margin-right: 40px;
            justify-content: right;

            button {
                font-size: 14px;
            }
            svg {
                margin: -2px 6px 0 -4px;
            }
        }
    }

    .comun_search {
        width: 100%;
        margin-bottom: 14px;
        display: flex;
        justify-content: center;

        input {
            width: 300px;
            height: 30px;
            padding: 0 35px 0 8px;
            font-size: 14px;
            border-radius: 8px;
            border: 1px solid rgba(112, 112, 112, 0.224);
        }

        .lupa svg {
            width: 25px;
            height: 25px;
            margin: 2px 8px 0 -30px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 400px;
            transition: all 420ms;
            cursor: pointer;
            fill: #153a61;

            &:active {
                transform: scale(0.8);
            }
        }
    }

    .filtro {
        width: 100%;
        padding: 8px 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(112, 112, 112, 0.224);
        box-shadow: 3px 6px 10px rgba(112, 112, 112, 0.224);

        .calendar {
            width: 150px;
            margin: 8px;

            svg {
                width: 18px;
                position: absolute;
                margin-left: -26px;
                cursor: pointer;
            }

            input {
                width: 100%;
                height: 25px;
                padding-right: 30px;
                border: solid 1px rgb(200, 200, 200);
            }
        }

        input[type="checkbox"] {
            width: 20px;
            margin-left: 20px;
            cursor: pointer;
        }

        label {
            width: 20%;
            margin-left: 4px;
            cursor: pointer;

            strong {
                color: rgb(255, 0, 0);
            }
        }

        .btn-secondary {
            width: 80px;
            padding: 2px 0;
            margin: 0;
            font-size: 12px;
            transition: all 400ms;

            &:active {
                transform: scale(0.9);
            }
        }
    }

    .columns {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        border-radius: 8px 0;
        align-items: center;

        h4 {
            width: 100%;
            height: 40px;
            padding: 4px;
            background-color: rgb(240, 240, 240);
            color: rgb(109, 106, 106);
            font-size: 14px;
            font-weight: bold;
            border: 1px solid rgba(112, 112, 112, 0.224);

            svg {
                width: 20px;
                margin-right: 2px;
                transition: all 400ms;
                cursor: pointer;

                &:active {
                    transform: scale(0.9);
                }
            }
        }
    }

    .rows,
    .rows_two {
        width: 100%;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: rgb(80, 133, 194);
            
        }

        &:hover > p {
            color: rgb(255, 255, 255);
        }

        &:before {
            content: "";
            margin-left: -2px;
            width: 10px;
            background-color: rgb(4, 179, 4);
        }

        p,
        a {
            width: 100%;
            padding: 2px 0 2px 4px;
            font-size: 12px;
            color: rgb(109, 106, 106);
            border: 1px solid rgba(200, 200, 200, 0.224);
        }

        a svg {
            width: 25px;
            fill: rgb(21, 58, 97);
            cursor: pointer;
            transition: all 400ms;

            &:active {
                transform: scale(0.9);
            }
        }

        p,
        p:first-child {
            margin: 0;
        }
    }

    .rows_two {
        &:before {
            content: "";
            margin-left: -2px;
            width: 10px;
            background-color: rgb(255, 0, 0);
        }
    }

    .total {
        width: 100%;
        padding: 4px 8px;
        border: 1px solid rgba(112, 112, 112, 0.224);
    }
}
