main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: var(--textColor);
  background-color: var(--bkgColorBody);
}

body,
* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid rgb(100, 100, 100);
    background-color: rgb(100, 100, 100);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(230, 230, 230);
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: var(--color-blue);
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: var(--color-blue-hover);
  }
}

img {
  width: 100%;
}

.icon-profile {
  margin: 0 8px 0 0;
}
