* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

/* Definimos las variables CSS para ambos temas */
:root {
  color-scheme: theme-light theme-dark;
  --bkgColor: rgb(255, 255, 255);
  --bkgColorBody: rgb(230, 230, 230);
  --bkgColorList: rgb(240, 240, 240);
  --bkgColorCorporate: rgb(23, 43, 68);
  --bkgColorAlert: rgb(248, 58, 58);
  --bkgColorTrans: rgba(240, 240, 240, 0.5);
  --bkgBlue: rgb(222, 234, 243);
  --bkgCard: rgba(255, 255, 255, 0.7);
  --bkgShadow: rgba(170, 170, 170, 0.8);
  --bkgHoverRow: rgba(80, 133, 194, 0.7);
  --bkgInbox: rgb(255, 255, 255);
  --textDatePicker: rgb(109, 176, 255);
  --textRed: rgb(229, 58, 58);
  --textBlue: rgb(23, 43, 68);
  --textColor: rgb(100, 103, 119);
  --textGray: rgb(109, 106, 106);
  --textList: rgb(30, 30, 30);
  --textInverted: rgb(80, 80, 80);
  --textPrimary: rgb(103, 103, 103);
  --textAdditional: rgb(100, 103, 119);
  --logoCard: url(../../images/logo/logo.svg);
  --colorThumb: rgb(180, 180, 180);
  --colorInput: rgba(112, 112, 112, 0.8);
  --colorHover: rgb(211, 219, 243);
  --colorHoverRow: rgb(255, 255, 255);
  --colorHoverList: rgb(216, 216, 216);
  --colorSelect: rgb(23, 43, 68);
  --colorSelectList: color-mix(in srgb, rgb(50, 93, 208), white 30%);
  --colorBorder: rgb(239, 241, 245);
  --colorBorderList: rgb(190, 190, 190);
  --colorIcon: rgb(221, 221, 221);
  --colorFieldsBorder: rgb(242, 244, 247);
  --colorScrollbar: rgb(180, 191, 208);
  --colorScrollbarHover: rgb(162, 171, 187);
  --colorFitness: rgb(100, 103, 119);
  --fieldsetBorder: rgba(68, 47, 47, 0.224);
  --fieldsBorder: rgb(160, 160, 160);
  --colorLinck: rgb(34, 128, 209);

  --color-print: rgb(100, 103, 119);
  --color-corporate: rgb(23, 43, 68);

  --color-accent: rgb(76, 225, 182);
  --color-light-accent: rgb(85, 229, 187);
  --color-accent-hover: rgb(68, 202, 163);

  --color-additional: rgb(153, 153, 153);
  --color-additional-hover: rgb(136, 136, 136);

  --color-yellow: rgb(246, 218, 110);
  --color-light-yellow: rgb(244, 221, 135);
  --color-yellow-hover: rgb(221, 196, 99);

  --color-violet: rgb(200, 143, 250);

  --color-red: rgb(255, 72, 97);
  --color-dark-red: rgb(114, 28, 36);
  --color-light-red: rgb(248, 142, 125);
  --color-very-light-red: rgb(248, 215, 218);
  --color-red-hover: rgb(229, 64, 87);

  --color-light-blue: rgb(135, 195, 247);
  --color-button: rgb(23, 43, 68);
  --color-button-hover: rgb(46, 64, 86);

  --color-blue: rgb(23, 43, 68);
  --color-blue-hover: rgb(20, 38, 61);

  --color-black: rgb(0, 0, 0);
  --color-gray: rgb(120, 121, 133);
  --color-light-gray: rgb(216, 223, 233);
  --color-dusty-white: rgb(221, 221, 221);
  --color-white: rgb(255, 255, 255);
  --color-dark: rgb(62, 62, 62);

  --color-green: rgb(184, 233, 134);
  --color-green-hover: rgb(165, 209, 120);

  --color-dark-fields-border: rgb(51, 51, 58);
  --color-black-background: rgb(35, 35, 41);
  --color-background-body: rgb(242, 244, 247);
  --color-text: rgb(100, 103, 119);
  --color-hover: rgb(250, 251, 254);
}

/* Tema oscuro usando el atributo data-theme */
[data-theme="theme-dark"] {
  --bkgColor: rgb(35, 35, 41);
  --bkgColorBody: rgb(42, 42, 49);
  --bkgColorList: rgb(30, 30, 30);
  --bkgColorCorporate: rgb(23, 43, 68);
  --bkgColorAlert: rgb(224, 0, 0);
  --bkgColorTrans: rgba(0, 0, 0, 0.5);
  --bkgBlue: rgb(34, 65, 89);
  --bkgCard: rgba(35, 35, 41, 0.7);
  --bkgShadow: rgba(62, 62, 62, 0.8);
  --bkgHoverRow: rgba(80, 133, 194, 0.7);
  --bkgInbox: rgb(32, 32, 32);
  --textDatePicker: rgb(6, 50, 99);
  --textRed: rgb(232, 81, 81);
  --textBlue: rgb(62, 115, 182);
  --textColor: rgb(221, 221, 221);
  --textGray: rgb(189, 189, 189);
  --textList: rgb(220, 220, 220);
  --textInverted: rgb(242, 242, 242);
  --textPrimary: rgb(220, 220, 220);
  --textAdditional: rgb(153, 153, 153);
  --logoCard: url(../../images/logo/logo_blanco.svg);
  --colorThumb: rgb(100, 100, 100);
  --colorInput: rgb(149, 136, 134);
  --colorHover: rgb(56, 55, 63);
  --colorHoverRow: rgb(255, 255, 255);
  --colorHoverList: rgb(43, 43, 43);
  --colorSelect: rgb(69, 85, 105);
  --colorSelectList: rgb(50, 93, 208);
  --colorBorder: rgb(51, 50, 70);
  --colorBorderList: rgb(0, 0, 0);
  --colorIcon: rgb(96, 95, 123);
  --colorFieldsBorder: rgb(51, 51, 58);
  --colorScrollbar: rgb(96, 96, 113);
  --colorScrollbarHover: rgb(111, 111, 127);
  --colorFitness: rgb(255, 255, 255);
  --fieldsetBorder: rgba(148, 147, 147, 0.224);
  --fieldsBorder: rgb(51, 51, 58);
  --colorLinck: rgb(112, 187, 253);
}

/* Tema oscuro usando prefers-color-scheme *
@media (prefers-color-scheme: dark) {
  :root:not([data-theme="theme-light"]) {
    --bkgColor: #232329;
    --bkgColorBody: #2a2a31;
    --bkgColorList: rgb(30, 30, 30);
    --bkgColorCorporate: #172b44;
    --bkgColorAlert: #e00000;
    --bkgColorTrans: rgba(0, 0, 0, 0.5);
    --bkgBlue: rgb(34, 65, 89);
    --bkgCard: rgba(35, 35, 41, 0.7);
    --bkgShadow: rgba(62, 62, 62, 0.8);
    --bkgHoverRow: rgba(80, 133, 194, 0.7);
    --bkgInbox: rgb(32, 32, 32);
    --textDatePicker: #063263;
    --textRed: rgb(232, 81, 81);
    --textBlue: #3e73b6;
    --textColor: #dddddd;
    --textGray: #bdbdbd;
    --textList: rgb(220, 220, 220);
    --textInverted: rgb(242, 242, 242);
    --textPrimary: rgb(220, 220, 220);
    --textAdditional: #999999;
    --logoCard: url(../../images/logo/logo_blanco.svg);
    --colorThumb: rgb(100, 100, 100);
    --colorInput: #958886;
    --colorHover: #38373f;
    --colorHoverRow: #ffffff;
    --colorHoverList: rgb(43, 43, 43);
    --colorSelect: rgb(69, 85, 105);
    --colorSelectList: rgb(50, 93, 208);
    --colorBorder: #333246;
    --colorBorderList: rgb(0, 0, 0);
    --colorIcon: #605f7b;
    --colorFieldsBorder: #33333a;
    --colorScrollbar: #606071;
    --colorScrollbarHover: rgb(111, 111, 127);
    --colorFitness: #ffffff;
    --fieldsetBorder: rgba(148, 147, 147, 0.224);
    --fieldsBorder: rgb(51, 51, 58);
    --colorLinck: rgb(112, 187, 253);
  }
}
*/
