* {
  box-sizing: border-box;
}

.cont_chat {
  position: relative;
  min-height: calc(100vh - 60px);
  margin: -10px -16px -30px;
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);

  .chat_lateral {
    width: 300px;
    min-width: 300px;
    padding: 0;
    border-right: 1px solid rgb(168, 168, 168);

    .buscar_chat {
      width: 90%;
      position: relative;
      margin-bottom: 20px;
      margin: 10px auto;

      input {
        width: 100%;
        height: 30px;
        padding-left: 6px;
        padding-right: 28px !important;
        border-radius: 8px;
        border: none;
        background-color: rgb(233, 233, 233);

        &:focus {
          outline: none;
        }
      }

      svg {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 4px;
        top: 2px;
        padding: 2px;

        &.icon-padding {
          right: 2px;
          padding: 6px;
        }
      }
    }

    .sugerencia {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .nota {
        width: 80%;
        text-align: center;
        font-size: 14px;
        margin: 100px 0;
      }
    }

    .sugerencias_chat {
      width: 100%;
      height: calc(100vh - 110px);
      padding: 0 4px 12px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: var(--bkgColor);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 1px solid var(--colorThumb);
        background-color: var(--colorThumb);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--textColor);
      }
    }

    .usuarios_chat {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      padding: 9px 4px 2px 8px;

      &:hover {
        background-color: color-mix(in srgb, rgb(23, 43, 68), white 70%);
      }

      img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 8px;
        margin-bottom: 6px;
      }

      svg {
        position: relative;
        width: 45px;
        min-width: 45px;
        height: 45px;
        min-height: 45px;
        margin-top: 0;
        padding: 0;
        margin-bottom: 12px;
        margin-right: 8px;
        border-radius: 50%;

        color: var(--color-corporate);
        border: 2px solid var(--color-corporate);
      }

      .datos_chat {
        width: 100%;

        h4 {
          width: 100%;
          font-size: 12px;
          font-weight: bold;
          color: rgb(70, 70, 70);

          span {
            position: absolute;
            top: 0;
            right: 8px;
            font-size: 10px;
            font-style: italic;
            color: rgb(150, 150, 150);
          }
        }

        h5 {
          width: 100%;
          font-size: 11px;
          font-style: italic;
          color: rgb(110, 110, 110);
        }

        h6 {
          width: 100%;
          font-size: 9px;
          padding-right: 6px;
          text-align: right;
          color: rgb(160, 160, 160);
        }
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;

      .buscar_chat {
        width: 90%;

        input {
          height: 50px;
          font-size: 16px;
          padding-left: 8px;
          padding-right: 40px;
          border-radius: 8px;
        }

        svg {
          width: 34px;
          height: 34px;
          right: 6px;
          top: 8px;
          padding: 0;

          &.icon-padding {
            right: 2px;
            padding: 4px;
          }
        }
      }

      .sugerencia {
        .nota {
          font-size: 18px;
        }
      }

      .usuarios_chat {
        width: 90%;
        margin-left: 18px;

        img {
          width: 60px;
          height: 60px;
          margin-right: 12px;
        }

        .datos_chat {
          width: 100%;

          h4 {
            font-size: 16px;

            span {
              font-size: 13px;
            }
          }

          h5 {
            width: 100%;
            font-size: 14px;
          }

          h6 {
            font-size: 10px;
            padding-right: 6px;
          }
        }
      }
    }
  }

  .vista_chat {
    width: 100%;
    position: relative;

    .barra_chat {
      width: 100%;
      height: 53px;
      padding: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-bottom: 1px solid rgba(168, 168, 168, 0.3);

      svg:not(.icono_usuario) {
        display: none;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 8px;
        margin-right: 6px;
      }

      .icono_usuario {
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        margin-top: 0;
        padding: 0;
        margin-left: 8px;
        margin-right: 6px;
        border-radius: 50%;

        color: var(--color-corporate);
        border: 2px solid var(--color-corporate);
      }

      h4 {
        width: 100%;
        font-size: 16px;
      }

      @media screen and (max-width: 576px) {
        padding-left: 8px;

        svg:not(.icono_usuario) {
          display: block;
          width: 40px;
          height: 40px;
          color: rgb(70, 70, 70);
        }

        img {
          margin-left: 0;
        }

        .icono_usuario {
          margin-left: 0;
        }

        h4 {
          font-size: 18px;
        }
      }
    }

    ._date {
      position: sticky;
      top: 8px;
      left: 50%;
      width: min-content;
      margin: 0;
      padding: 2px 8px;
      border-radius: 8px;
      text-align: center;
      font-size: 12px;
      white-space: nowrap;
      color: rgb(255, 255, 255);
      border: 1px solid rgb(124, 122, 122);
      background-color: rgba(255, 255, 255, 0.9);
      transform: translateX(-50%);
      z-index: 12;
    }

    .conversacion_chat {
      position: relative;
      width: 100%;
      display: block;
      height: calc(100vh - 170px);
      background-color: rgb(255, 255, 255);
      padding: 0 100px 12px;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: var(--bkgColor);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 1px solid var(--colorThumb);
        background-color: var(--colorThumb);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--textColor);
      }

      .contain_right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 8px 0;
      }

      .contain_left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 8px 0;
      }

      .globo {
        position: static;
        width: min-content;
        height: min-content;
        max-width: 600px;
        margin: 0 15px;
        border-radius: 8px;

        .contains_img_chat {
          position: relative;
          width: 100%;
          height: 0;
          overflow: hidden;

          &.in_zoom {
            position: fixed;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1000;
            background-color: rgba(70, 70, 70, 0.8);
            transition: all 0.3s ease-in-out;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 8px 8px 0 0;
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .contains_tails {
          position: relative;
          width: 100%;
          height: 0;
        }

        img {
          width: 150px;
          height: 90px;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
          transition: all 0.3s ease-in-out;
        }

        p {
          width: max-content;
          max-width: 100%;
          height: min-content;
          font-size: 14px;
          line-height: 1.2;
          padding: 8px 8px 0;
          margin: 8px 6px 0;
          color: rgb(92, 92, 92);
        }

        h6 {
          position: relative;
          width: auto;
          font-size: 9px;
          text-align: right;
          white-space: nowrap;
          margin: 0 8px 4px;
          color: rgb(140, 140, 140);
          z-index: 2;
        }

        &.izquierda {
          border-radius: 8px 8px 8px 0;
          background-color: color-mix(in srgb, rgb(23, 43, 68), white 70%);

          .message_tail {
            position: absolute;
            left: -9px;
            bottom: -1px;
            width: 11px;
            height: 20px;
            color: color-mix(in srgb, rgb(23, 43, 68), white 70%);
            z-index: 1;
          }
        }

        &.derecha {
          border-radius: 8px 8px 0 8px;
          background-color: color-mix(in srgb, rgb(109, 110, 110), white 48%);

          .message_tail {
            position: absolute;
            right: -10px;
            bottom: -1px;
            width: 11px;
            height: 20px;
            color: color-mix(in srgb, rgb(109, 110, 110), white 48%);
            z-index: 1;
          }
        }
      }

      @media screen and (max-width: 576px) {
        width: calc(100% - 20px);
        margin-left: 10px;
        padding-left: 6px;
        padding-right: 16px;

        .globo {
          max-width: 80%;
        }
      }
    }

    .envio_chat {
      width: 100%;
      padding: 7px 6px;
      margin-top: -6px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-top: 1px solid rgba(168, 168, 168, 0.3);

      label {
        display: block;
        position: relative;
        width: 32px;
        height: 40px;
        margin-left: 12px;
        margin-right: -32px;
        cursor: pointer;
        z-index: 12;

        svg {
          margin: 6px auto;
          width: 28px;
          height: 28px;
          color: color-mix(in srgb, rgb(23, 43, 68), white 5%);

          &.on_refresh {
            animation: refresh 1s infinite linear;
          }
        }

        &._inactive {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      button {
        border: none;
        background: none;

        svg {
          width: 34px;
          height: 34px;
          color: color-mix(in srgb, rgb(23, 43, 68), white 5%);

          &.on_refresh {
            animation: refresh 1s infinite linear;
          }
        }

        &._inactive {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      input {
        width: 90%;
        max-width: 600px;
        margin: 0;
        margin-right: 6px;
        padding: 12px;
        padding-left: 30px;
        border-radius: 12px;
        border: 1px solid color-mix(in srgb, rgb(23, 43, 68), white 65%);
        overflow-wrap: initial;
        flex-wrap: wrap;
        background-color: rgb(255, 255, 255);

        &[type="file"] {
          display: none;
        }

        &:focus {
          outline: none;
        }
      }

      @media screen and (max-width: 576px) {
        label {
          width: 32px;
          height: 40px;
          margin-left: 12px;
          margin-right: -42px;
        }

        input {
          width: 80%;
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .vista_chat_vacio {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);

    h3 {
      font-size: 22px;
      font-style: italic;
    }
  }
}
