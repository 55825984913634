.toggle-btn {
  justify-content: flex-start;
  display: inline-flex;
  font-size: 12px;
  padding: 4px 0;
  margin-bottom: 0;
  cursor: pointer;

  .toggle-btn__input {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}

.toggle-btn__input-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 15px;
  display: block;
  border-radius: 23px;
  position: relative;
  margin: 3px 8px 0;

  border: none;
  background-color: rgba(170, 170, 170, 0.3);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid rgb(189, 189, 189);
    transition: 0.3s;
    box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
    background-color: rgb(255, 255, 255);
  }
}

.toggle-btn__input:checked ~ .toggle-btn__input-label {
  &:after {
    background: rgb(0, 202, 145);
    border: 1px solid rgb(0, 202, 145);
    left: 100%;
    transform: translateX(-100%);
  }
}
