.tool_bar {
  position: relative;
  margin: 0 0 6px;
  padding: 4px 0;
  display: flex;
  direction: rtl;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background: var(--bkgColor);

  svg {
    margin: 0 5px;
    width: 24px;
    min-width: 20px;
    transition: all 400ms;
    cursor: pointer;
    fill: var(--textBlue);
    color: var(--textBlue);

    &:hover {
      color: rgb(61, 118, 137);
    }

    &:active {
      transform: scale(0.9);
    }

    &.on_refresh {
      animation: refresh 1s infinite linear;
    }

    &.btn_refresh {
      @media screen and (max-width: 576px) {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }

  .calendar {
    svg {
      margin: 2px 5px 0;
      width: 20px;
      height: 20px;
    }
  }

  p {
    width: min-content;
    font-size: 12px;
    margin: 0 0 0 20px;
  }

  select {
    height: 25px;
    margin: 0 8px 0 6px;
    border-radius: 6px;
    outline: none;
    border: solid 1px var(--colorInput);
    background-color: var(--bkgColorBody);
    color: var(--textColor);
  }

  .calendar {
    position: relative;
    width: 100px;
    margin: 0 8px;

    svg {
      position: absolute;
      top: 1px;
      right: 0;
      width: 18px;
    }

    input {
      width: 100%;
      height: 25px;
      border: solid 1px rgb(200, 200, 200);
    }
  }

  ._in_row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    direction: ltr;
  }

  ._in_column {
    direction: ltr;

    .input {
      input {
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 860px) {
    direction: ltr;
  }

  @media screen and (max-width: 624px) {
    padding: 8px;
    flex-flow: row wrap;

    svg {
      margin-left: 12px;
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }

    ._in_row {
      margin: 8px;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 8px;
    flex-flow: row wrap;

    svg {
      margin-left: 8px;
      margin-right: 8px;
    }

    ._in_row {
      margin-left: 8px;

      .calendar {
        position: relative;
        width: 120px;
        margin: 0 8px;

        svg {
          position: absolute;
          top: 8px;
          right: 2px;
          width: 20px;
          margin: 0;
        }

        input {
          width: 100%;
          height: 35px;
          font-size: 1rem !important;
          border: solid 1px rgb(200, 200, 200);
        }
      }
    }
  }
}

.title_bar {
  position: absolute;
  top: 40px;
  left: 50%;
  padding: 1px;
  clip-path: polygon(
    calc(100% - 20px) 0,
    100% 50%,
    calc(100% - 20px) 100%,
    20px 100%,
    0% 50%,
    20px 0
  );
  transform: translateX(-50%);
  background: var(--bkgColorBody);
  z-index: 110;

  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 4px 32px 4px 20px;
    background-color: var(--bkgColorCorporate);

    clip-path: polygon(
      calc(100% - 20px) 0,
      calc(100% - 1px) 50%,
      calc(100% - 20px) 100%,
      20px 100%,
      calc(0% + 1px) 50%,
      20px 0
    );
  }

  svg {
    width: 50px;
    height: 26px;
    padding: 2px;
    margin-right: 18px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 30px;
    cursor: pointer;
    transition: all 200ms;

    &:active {
      transform: scale(0.9);
    }
  }

  h3 {
    font-size: 18px;
    color: rgb(255, 255, 255);
    white-space: nowrap;
  }
}
