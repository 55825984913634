.alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: var(--bkgShadow);
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 200;

  .alert__content {
    position: relative;
    width: min-content;
    min-width: 250px;
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 10px;
    border-radius: 12px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.9);
    background-color: var(--bkgColor);

    .to_scroll {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: 0;
      border-top: 1px solid rgba(62, 62, 62, 0.3);
      border-bottom: 1px solid rgba(62, 62, 62, 0.3);

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: var(--bkgColor);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 1px solid var(--colorThumb);
        background-color: var(--colorThumb);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--textColor);
      }
    }

    .alert__icon {
      padding: 18px;
      display: flex;

      svg {
        margin: auto 0;
        fill: var(--textColor);

        &.green {
          color: green;
          stroke-width: 0.6px;
          stroke: green;
        }

        &.red {
          color: red;
          stroke-width: 0.6px;
          stroke: rgb(255, 255, 255);
          filter: drop-shadow(0 0 1px rgb(255, 255, 255));
        }

        &.yellow {
          color: yellow;
          stroke-width: 0.6px;
          stroke: rgb(0, 0, 0);
          filter: drop-shadow(0 0 2px rgb(0, 0, 0));
        }
      }
    }

    p {
      position: relative;
      margin: 16px 8px;
      text-align: center;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      color: var(--textColor);

      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
    }

    .close {
      font-size: 14px;
      font-weight: bold;
      margin: 18px 0 0;
      padding: 4px 12px;
      text-decoration: none;
      cursor: pointer;
      color: var(--textColor);

      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }

      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }

    .in_row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;

      .close {
        display: block;
        width: 100%;
        margin: 18px 4px 0;
        text-align: center;
      }
    }

    &.alert-info {
      background-color: var(--color-blue);
      border-color: var(--color-blue);

      &.alert--bordered .alert__icon {
        background-color: var(--color-blue);
      }

      &.alert--colored .alert__icon {
        background-color: var(--color-light-blue);
      }

      &.alert--neutral .alert__icon svg {
        fill: var(--color-blue);
      }
    }

    &.alert-success {
      background-color: var(--color-accent);
      border-color: var(--color-accent);

      &.alert--bordered .alert__icon {
        background-color: var(--color-accent);
      }

      &.alert--colored .alert__icon {
        background-color: var(--color-light-accent);
      }

      &.alert--neutral .alert__icon svg {
        fill: var(--color-accent);
      }
    }

    &.alert-warning {
      background-color: var(--color-yellow);
      border-color: var(--color-yellow);

      &.alert--bordered .alert__icon {
        background-color: var(--color-yellow);
      }

      &.alert--colored .alert__icon {
        background-color: var(--color-light-yellow);
      }

      &.alert--neutral .alert__icon svg {
        fill: var(--color-yellow);
      }
    }

    &.alert-danger {
      background-color: var(--color-red);
      border-color: var(--color-red);

      &.alert--bordered .alert__icon {
        background-color: var(--color-red);
      }

      &.alert--colored .alert__icon {
        background-color: var(--color-light-red);
      }

      &.alert--neutral .alert__icon svg {
        fill: var(--color-red);
      }
    }

    &.alert--bordered {
      background-color: transparent;

      p {
        color: var(--textColor);
      }

      .close {
        color: var(--color-additional);
      }
    }

    &.alert--neutral {
      background-color: transparent;
      border-color: var(--colorFieldsBorder);

      p {
        color: var(--textColor);
      }

      .close {
        color: var(--color-additional);
      }

      svg {
        fill: var(--textColor);
      }

      .alert__icon {
        border-right: 1px solid #f2f4f7;
      }
    }

    &.search_flotante {
      width: 100%;
      max-width: 350px;

      h4 {
        position: relative;
        width: 100%;
        padding: 18px 0 16px;
        font-size: 1.1rem;
        text-align: center;
        white-space: pre-wrap;

        svg {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 32px;
          height: 32px;
          padding: 2px;
          cursor: pointer;
        }
      }

      a {
        padding: 6px 26px;
        font-size: 1rem;
        border: 1px solid var(--color-dark);
        border-radius: 12px;
      }

      ._error {
        width: 100%;
        margin: 16px 0 0;
        font-size: 0.9rem;
        color: rgb(255, 32, 32);
        text-align: center;
        white-space: pre-wrap;
      }

      .cont_search {
        position: relative;
        width: 95%;
        max-width: 300px;
        display: flex;
        justify-content: left;

        input {
          width: 300px;
          padding: 8px 35px 8px 8px;
          font-size: 1rem;
          border-radius: 8px;
          outline: none;
          border: 1px solid var(--colorInput);
          background: var(--bkgInbox);
          color: var(--textColor);
        }

        svg {
          width: 25px;
          height: 25px;
          margin: 4px 8px 0 -30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          pointer-events: none;
          fill: var(--textColor);
          color: var(--textColor);

          &.icon_close {
            padding: 2px;
            color: var(--textColor);
          }
        }
      }

      @media screen and (max-width: 576px) {
        .cont_search {
          justify-content: center;
          margin: 8px 0 0;

          input {
            width: 100%;
            font-size: 16px !important;
            padding: 10px 35px 10px 8px;
          }

          svg {
            margin: 4px 8px 0 -36px;
            width: 32px;
            height: 32px;

            &.icon_close {
              padding: 4px;
            }
          }
        }
      }
    }

    &._resumen {
      width: 90%;
      max-width: 400px;

      h4 {
        margin: 18px 0;
      }

      h5 {
        margin: 18px 0 24px;
        font-size: 1rem;
        font-weight: bold;
        color: var(--color-dark);
      }

      p {
        position: relative;
        width: 80%;
        margin: 0;
        padding: 14px 6px 2px;
        font-size: 0.9rem;
        text-align: left;
        border-bottom: 1px solid rgba(62, 62, 62, 0.4);

        span {
          float: right;
        }

        i {
          font-size: 0.8rem;

          &.dev_pack {
            position: relative;
            display: block;
            margin: 8px 0;
            padding-left: 18px;

            input[type="checkbox"] {
              position: absolute;
              top: 0;
              left: -10px;
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
          }
        }
      }

      a {
        padding: 6px 24px;
        font-size: 1rem;
        border: 1px solid var(--color-dark);
        border-radius: 12px;
        color: var(--textColor);
      }

      .cont_btns {
        padding-top: 32px;
        padding-bottom: 6px;

        a {
          margin: 0 12px;
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: 576px) {
      width: 90%;
      max-width: 90vw;
      /*  max-width: 300px; */
    }
  }
}

/* .alert__resalta_input {
  position: relative;
} */ 

.alert__input {
  display: block;
  position: absolute;
  left: 0;
  top: 36px;
  width: min-content;
  padding: 3px 12px 2px;
  margin-top: 4px;
  margin-right: 18px;
  font-size: 10px;
  border-radius: 8px;
  white-space: nowrap;
  color: rgb(240, 240, 240);
  z-index: 90;
  /* color: var(--textPrimary) */
  background-color: var(--bkgColorAlert);

  &._up {
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid var(--bkgColorAlert);
    }
  }

  &._down {
    top: -32px;
    left: 32px;
    z-index: 90;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: 16px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--bkgColorAlert);
    }
  }

  &._area {
    top: 106px;
  }
}

.cont_messages {
  padding: 2rem 1rem;

  h2 {
    font-size: 1.8rem;
  }

  button {
    margin-top: 3rem;
    font-size: 1rem !important;
  }
}
