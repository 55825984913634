@keyframes refresh {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.panel {
  transition: 0.3s;

  &.panel--collapse {
    height: 108px;

    &.panel--divider .panel__title,
    .panel__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.panel--success,
    &.panel--primary,
    &.panel--warning,
    &.panel--danger {
      height: 72px;
    }

    .panel__body {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &.panel--narrow {
    .panel__title {
      margin-bottom: 0;
    }
  }

  &.panel--divider {
    .panel__title {
      margin-bottom: 15px;
    }

    .panel__content {
      text-align: left;
      padding-top: 0px;
      padding-bottom: 40px;

      .tabs--bordered-bottom {
        .nav-item {
          border-bottom: 1px solid #dee2e6;
          .nav-link {
            background-color: var(--bkgColor);
            color: var(--textColor);
          }
        }
      }
    }
  }

  &.panel--success,
  &.panel--primary,
  &.panel--warning,
  &.panel--danger {
    .panel__body {
      padding: 0;
    }

    .panel__title {
      padding-right: 40px;
      padding-left: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .panel__btns {
      top: 13px;

      svg {
        fill: var(--color-white);
      }
    }

    .panel__content {
      padding-right: 35px;
      padding-left: 30px;
      padding-top: 0px;
      padding-bottom: 40px;

      @media screen and (max-width: 576px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  &.panel--success {
    .panel__title {
      background-color: var(--color-accent);
    }
  }

  &.panel--danger {
    .panel__title {
      background-color: var(--color-red);
    }
  }

  &.panel--warning {
    .panel__title {
      background-color: var(--color-yellow);
    }
  }

  &.panel--primary {
    .panel__title {
      background-color: var(--color-blue);
    }
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0px;
    border-radius: 5px;
    background-color: var(--bkgColor);
    opacity: 0.4;
  }

  svg {
    fill: #172b44;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }
}

.panel__body {
  position: relative;
  padding-right: 35px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
  transition: height 0.3s;
}

.panel__btns {
  position: absolute;
  top: 0.3rem;
  right: 0.9rem;
  width: 24px;
  height: 24px;

  &._en_caja {
    top: -2rem;
    right: 4rem;

    svg {
      width: 2.3rem;
      height: 2.3rem;
    }
  }

  @media screen and (max-width: 576px) {
    width: 32px;
    height: 32px;
  }

  /* .panel__btn, */
  button {
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    padding: 0 8px;
    cursor: pointer;
    margin-left: 5px;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-additional);

      &.on_refresh {
        animation: refresh 1s infinite linear;
      }
    }

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 576px) {
      width: 32px;
      height: 32px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.panel__title {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;

    @media screen and (max-width: 576px) {
      padding-left: 18px;
    }
  }

  .subhead {
    text-align: left;
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.panel__label {
  background-color: var(--color-accent);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;
  left: 100%;
  margin-left: 10px;
}

.panel__icon {
  display: inline-block;
  margin-right: 5px;
}
