.account {
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: url(../../images/delivery-tracking.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;

  &.account--photo {
    background: url(../../images/404/bg_404.png) no-repeat center;
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #ffffff;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: var(--color-accent);
      }
    }

    input {
      color: #ffffff;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }

    .account__title {
      color: #ffffff;
    }
  }
}

.account_background {
  width: 100%;
  display: flex;
  background-color: rgba(23, 43, 68, 0.7);
  /* background-color: var(--bkgColorTrans); */
}

.account__wrapper {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 10px;
}

.account__card {
  width: 100%;
  max-width: 500px;
  padding: 270px 62px 25px;
  border-radius: 12px;
  box-shadow: 0 3px 12px rgb(0, 0, 0);
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: top center;
  background-image: var(--logoCard);
  background-color: var(--bkgCard);

  .account__subhead {
    margin: 18px 0 32px;
  }

  p {
    margin: 15% 0 18px;
    font-size: 14px;
    text-align: center;
    color: var(--textPrimary);
  }
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__btn-login {
  margin: 32px 0 12px;
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--color-additional);
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -22px;
  right: 12px;

  a {
    color: var(--colorLinck);

    &:hover {
      color: var(--color-blue-hover);
      text-decoration: none;
    }
  }

  @media screen and (max-width: 576px) {
    font-size: 14px;
    bottom: -24px;
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  margin-right: 10px;
  transition: all 0.3s;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: color-mix(in srgb, #4766a4, black 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: color-mix(in srgb, #c74d4d, black 10%);
    }
  }
}

.account__head {
  margin-top: 190px;
  margin-bottom: 30px;
  padding-left: 10px;
  /*  border-left: 4px solid var(--color-blue) */
}

.account__logo {
  font-weight: 700;
  color: var(--textPrimary);
}

.account__logo-accent {
  color: var(--color-blue);
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: var(--color-blue);
    transition: all 0.3s;

    &:hover {
      color: var(--color-blue-hover);
      text-decoration: none;
    }
  }
}

.account__collapse {
  position: relative;
  margin: 18px 0 12px;

  &.account__collapse--language {
    min-width: 120px;
    display: block;

    & > button {
      height: 100%;
      padding: 0 10px;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border: none;
      background: transparent;
      transition: all 0.3s;
      color: var(--textPrimary);
    }
  }
}

.account__language-btn-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  color: var(--textPrimary);

  span {
    margin: 0 8px -6px 0;
    font-size: 22px;
  }
}

.account__collapse-content {
  width: 120px;
  position: absolute;
  left: 0;
  bottom: 40px;
  transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  background: var(--bkgColor);
  border-radius: 8px;
  z-index: 101;

  &.account__collapse-content--language {
    padding: 10px 0;
    width: 120px;

    .account__language-btn {
      padding: 4px 15px;
      width: 100%;
      border: none;
      background: transparent;
      cursor: pointer;
      text-align: left;
      font-size: 13px;
      line-height: 16px;
      transition: 0.3s;

      .account__language-btn-title {
        color: var(--textInverted);
      }
      &:hover {
        color: var(--color-accent);
      }
    }
  }

  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translate(-50%, 100%);
  }

  @media screen and (min-width: 520px) {
    width: 330px;
  }
}

.account__back {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }
}

@media screen and (max-width: 576px) {
  .account__card {
    padding: 270px 18px 0;
  }
}

@media screen and (max-width: 425px) {
  .account__btns {
    flex-wrap: wrap;
  }
}
