.dashboard {
  .dashboard__visitors-chart-title {
    text-align: left;

    span {
      color: var(--color-additional);
      font-size: 11px;
    }
  }

  .dashboard__visitors-chart-number {
    text-align: left;
    font-size: 48px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .dashboard__audience-table {
    text-align: left;

    .progress {
      margin-top: 10px;
    }

    .progress-bar {
      height: 10px;
    }
  }

  .dashboard__table-flag {
    width: 42px;
    height: 30px;
    margin-right: 25px;
  }

  .dashboard__bounce-percent {
    text-align: left;
    color: var(--color-accent);
    font-size: 48px;
    line-height: 48px;
    margin-top: 20px;
    margin-bottom: 45px;
  }

  .dashboard__product-img {
    max-height: 270px;
    height: 100%;
    padding: 10px 0 20px 0;
    margin-bottom: 15px;
    text-align: center;
    border-bottom: 1px solid var(--colorBorder);

    img {
      width: auto;
      max-width: 100%;
      max-height: 240px;
    }
  }

  .dashboard__product-title {
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .dashboard__product-subhead {
    text-align: left;
    font-size: 13px;
    line-height: normal;
  }

  .dashboard__table-orders {
    overflow: hidden;
    min-width: 410px;

    tbody td {
      padding: 16px 10px;
    }

    .dashboard__table-orders-title {
      position: relative;
      padding-left: 52px;
      display: flex;
      line-height: 15px;
      align-items: center;
    }
  }
  .dashboard__chart-pie--commerce,
  .dashboard__chart-pie--fitness {
    .recharts-legend-wrapper {
      @media screen and (min-width: 370px) {
        bottom: 0px !important;
      }
      @media screen and (min-width: 700px) {
        bottom: 70px !important;
      }
      @media screen and (min-width: 1020px) {
        bottom: -30px !important;
      }
      @media screen and (min-width: 1200px) {
        bottom: -55px !important;
      }
      @media screen and (min-width: 1400px) {
        bottom: -25px !important;
      }
      @media screen and (min-width: 1800px) {
        bottom: -30px !important;
      }
    }
  }

  .dashboard__table-orders-total {
    white-space: nowrap;
  }

  .dashboard__table-orders-img-wrap {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    left: 10px;
  }

  .dashboard__table-orders-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .dashboard__table-orders-amount {
    display: flex;

    div {
      width: 3px;
      height: 14px;
      display: inline-block;
      background-color: var(--color-accent);
      border-radius: 3px;
      margin-right: 3px;
      margin-left: 0px;
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      color: var(--color-additional);
      margin-right: 0px;
      margin-left: 5px;
      margin-top: auto;
      margin-bottom: auto;
    }

    &.dashboard__table-orders-amount--medium {
      div {
        background-color: var(--color-yellow);
      }
    }

    &.dashboard__table-orders-amount--low {
      div {
        background-color: var(--color-red);
      }
    }
  }

  .dashboard__table-orders-link {
    font-size: 12px;
    margin-top: 20px;
    display: block;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .dashboard__table-more {
    display: flex;

    & > button {
      margin: 0;
      padding: 0 5px;
      border: none;
      background-color: transparent;
      margin-left: auto;

      &:before {
        display: none;
      }

      svg {
        margin: 0;
        height: 20px;
        width: 20px;
        fill: var(--color-additional);
        transition: all 0.3s;
      }

      &:hover,
      &:not([disabled]):not(.disabled):active,
      &:focus {
        background: transparent;

        svg {
          fill: var(--color-accent);
        }
      }
    }

    & > div {
      min-width: 90px;
      width: 100%;
    }

    button {
      font-size: 13px;
    }
  }

  .dashboard__sales-toggle {
    label {
      margin: 0;

      &:after {
        box-shadow: none;
        background-color: var(--color-red);
      }
    }

    &.dashboard__sales-toggle-absolute {
      position: absolute;
      bottom: 8px;
      right: 12px;
    }
  }

  .dashboard__sales-toggle-left {
    margin-left: auto;
    margin-right: 12px;
    color: var(--color-additional);
    pointer-events: none;
  }

  .dashboard__sales-toggle-right {
    margin-left: 12px;
    margin-right: auto;
    color: var(--color-additional);
    pointer-events: none;
  }

  .dashboard__sales-report {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    p {
      margin-top: 0;
    }
  }

  .dashboard__sales-table {
    margin-top: 20px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }

    .table {
      width: 100%;
      max-width: 100vw;
      border-radius: 4px;
      overflow: hidden;

      tr {
        &:nth-last-child(2) {
          td {
            border-bottom: 1px solid var(--bkgBlue);
          }
        }

        &:last-child {
          td {
            &:nth-last-child(2) {
              border-right: 0 solid !important;
              border-right-color: inherit !important;
            }

            &:last-child {
              padding: 6px 8px !important;
            }
          }
        }

        th {
          padding: 0 6px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        td {
          padding: 6px 8px;
          white-space: nowrap;

          &:nth-last-child(2) {
            border-right: 1px solid var(--bkgBlue);
          }

          &:last-child {
            padding: 6px 8px !important;
          }

          &.con_icon {
            position: relative;
            padding-left: 42px;

            svg {
              position: absolute;
              top: 2px;
              left: 8px;
              width: 26px;
              height: 26px;
              padding: 2px;
              cursor: pointer;
            }
          }
        }

        th,
        td {
          overflow: hidden;

          &:nth-child(2) {
            max-width: 130px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &._no_margin {
          td {
            border: 0 solid !important;
            border-color: inherit !important;
            padding-bottom: 0;
          }
        }

        &._tr_row {
          cursor: pointer;
        }

        &._aceptado {
          background-color: rgba(0, 255, 34, 0.8);

          td {
            color: rgb(30, 30, 30);
          }
        }

        &._rechazado {
          background-color: rgba(255, 0, 34, 0.8);

          td {
            color: rgb(255, 255, 255);
          }
        }
      }

      @media screen and (max-width: 576px) {
        border-radius: 0;

        tr {
          th,
          td {
            &:first-child {
              max-width: 110px;
              border-left: none;
            }

            &:nth-child(2) {
              max-width: 110px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .dashboard__sales-report-title {
    color: var(--color-additional);
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .dashboard__sales-report-now {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .dashboard__sales-report-plan {
    font-size: 11px;
    line-height: 15px;
    color: var(--color-additional);
    opacity: 0.5;
    margin-bottom: 10px;
  }

  .dashboard__sales-report-value {
    margin-bottom: 0;
  }

  .dashboard__weight-stats {
    display: flex;
    margin-bottom: 20px;
  }

  .dashboard__weight-stat {
    margin-right: 40px;

    @media screen and (min-width: 768px) {
      margin-right: 80px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .dashboard__weight-stat-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .dashboard__weight-stat-value {
    font-size: 36px;
    line-height: 48px;

    @media screen and (min-width: 768px) {
      font-size: 48px;
      line-height: 62px;
    }

    &.dashboard__weight-stat-value--control {
      color: var(--color-yellow);
    }

    &.dashboard__weight-stat-value--total {
      color: var(--color-red);
    }
  }

  .dashboard__health-chart-card {
    text-align: center;
  }

  .dashboard__goal {
    font-size: 12px;
    color: var(--color-additional);
  }

  .dashboard__health-chart {
    position: relative;
  }

  .dashboard__health-chart-info {
    position: absolute;
    width: 100%;
    top: calc(50% - 55px);
    animation: label 1.5s ease-in;

    p {
      margin: 0;
    }
  }

  .dashboard__health-chart-number {
    font-size: 56px;
    line-height: 60px;
    color: var(--colorFitness);
  }

  .dashboard__health-chart-units {
    color: var(--color-additional);
    margin-top: 5px;
  }

  .dashboard__competitor {
    display: flex;
    padding: 10px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: calc(100% + 65px);
      top: 0;
      left: -30px;
      z-index: 0;
      opacity: 0;
      transition: all 0.3s;
      background: var(--colorHover);
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  .dashboard__competitor-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    min-width: 40px;
    z-index: 1;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .dashboard__competitor-info {
    text-align: left;
    z-index: 1;
  }

  .dashboard__competitor-name {
    font-weight: 500;
  }

  .dashboard__competitor-result {
    color: var(--color-red);
    margin-top: 0;
    font-size: 14px;
  }

  .dashboard__area {
    font-size: 11px;

    .recharts-legend-wrapper {
      left: 12px !important;
      bottom: 0 !important;
    }

    &.recharts-responsive-container {
      padding-left: 0;
      padding-right: 12px;
      margin-left: -16px;
    }
  }

  .dashboard__total {
    display: flex;
    margin-top: -15px;

    &.dashboard__total--area {
      margin-top: -55px;
    }
  }

  .dashboard__chart-container {
    display: flex;
    flex: 1;
    width: 0;
    min-width: 0;
    margin-bottom: -18px;

    @media (min-width: 1200px) {
      & {
        min-width: 0;
        /*  max-width: 180px */
      }
    }

    @media screen and (max-width: 576px) {
      margin-bottom: -14px;
    }

    .recharts-surface {
      padding: 0;

      g {
        height: 50px !important;
        max-height: 50px !important;
        overflow: auto;
      }
    }

    .custom_tooltip {
      padding: 8px 12px;
      border: 1px solid rgb(200, 200, 200);
      background-color: rgb(255, 255, 255);
      border-radius: 6px;

      .tooltip_title {
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0 0 8px;
      }

      .tooltip_label {
        font-size: 0.8rem;
        margin: 0 0 4px;
      }
    }
  }

  .dashboard__sales-container {
    width: 100%;
    min-width: 160px;
    margin: 0 18px 0;
    padding: 0 16px;

    @media screen and (max-width: 576px) {
      margin: 0;
      padding: 0 24px;
    }

    p {
      &.dashboard__sales-report-title {
        padding-left: 12px;
        margin-bottom: 18px;
      }

      &.dashboard__sales-report-now {
        margin-right: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;

        hr {
          margin: 4px 0;
        }
      }

      span {
        float: left;
        font-size: 0.8rem;
        font-weight: normal;
      }

      samp {
        line-height: 1.3;
        margin-left: 4px;
        text-align: right;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }

  .dashboard__total-text {
    padding-left: 18px;
    margin-top: 6px;

    .dashboard__total-carrier {
      font-size: 12px;
      color: var(--color-additional);
      margin-left: 5px;
    }

    i {
      text-transform: none;
    }

    svg {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    samp {
      display: inline-block;
      margin: 0;
      width: 10px;
      height: 10px;
    }

    @media screen and (max-width: 576px) {
      padding-left: 36px;
    }
  }

  .dashboard__trend-icon {
    fill: var(--color-accent);
    height: 24px;
    width: 24px;
    margin-top: auto;
    margin-left: -14px;
    margin-right: 6px;
    min-width: 24px;

    @media screen and (max-width: 576px) {
      margin-left: 4px;
    }
  }

  .dashboard__money-icon {
    fill: currentColor;
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin: auto 8px auto -6px;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
  }

  .dashboard__stat {
    display: flex;
    margin-top: 40px;
    margin-bottom: 10px;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .dashboard__stat-info {
      margin-right: 0px;
      margin-left: 30px;
      text-align: left;
      margin-top: auto;
      margin-bottom: auto;

      p {
        color: var(--color-additional);
      }
    }

    .dashboard__stat-number {
      margin-top: 10px;
    }

    .dashboard__stat-chart {
      position: relative;
      text-align: center;
    }

    .dashboard__stat-label {
      position: absolute;
      font-size: 18px;
      line-height: 20px;
      top: calc(50% - 10px);
      width: 100%;
      margin: 0;
      animation: label 1.5s ease-in;
      color: var(--color-dusty-white);
    }

    &.dashboard__stat--budget {
      text-align: center;
      flex-wrap: wrap;

      .dashboard__stat-chart {
        margin: auto;
      }

      .dashboard__stat-label {
        font-size: 36px;
      }

      .dashboard__stat-main {
        width: 100%;

        hr {
          margin-bottom: 30px;
          margin-top: 40px;
        }
      }

      .dashboard__stat-main-title {
        color: var(--color-additional);
      }

      .dashboard__stat-main-number {
        color: var(--color-red);
        font-size: 48px;
        line-height: 34px;
        margin-top: 15px;
      }

      .dashboard__stat-data {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        p {
          margin: 0;
        }
      }

      .dashboard__stat-data-number {
        font-size: 18px;
        line-height: 34px;
        font-weight: 500;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;

      .dashboard__stat-info {
        margin: 0;
      }

      .dashboard__stat-chart {
        margin-bottom: 15px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {
      .dashboard__stat-chart {
        svg,
        div {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }

  @keyframes label {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .dashboard__chart-pie {
    text-align: left;
    height: 280px !important;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 768px) {
      &.dashboard__chart-pie--crypto {
        height: 300px !important;

        .dashboard__chart-pie-container {
          height: 300px !important;
        }
      }

      &.dashboard__chart-pie--commerce {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
        }
      }

      &.dashboard__chart-pie--fitness {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .recharts-legend-wrapper {
        position: relative !important;
      }
    }
    @media screen and (max-width: 480px) {
      &.dashboard__chart-pie--crypto {
        height: 240px !important;

        .dashboard__chart-pie-container {
          height: 180px !important;
        }

        .dashboard__chart-legend {
          margin-left: -10px;

          li {
            margin-top: 0;
          }
        }
      }
    }
  }

  .dashboard__chart-pie-container {
    padding-left: 0px;
    height: 200px !important;
  }

  .dashboard__chart-legend {
    padding: 0;
    list-style: none;

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
      margin-right: 10px;
    }

    li {
      margin-top: 5px;
      color: var(--textColor);
    }
  }

  .dashboard__card-widget {
    padding-top: 25px;
    padding-bottom: 25px;
    /*  overflow: hidden */

    @media screen and (max-width: 576px) {
      h4 {
        padding-left: 18px;
      }
    }
  }

  .dashboard__card-subtitle {
    position: relative;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.9;

    b {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .dashboard__btc {
    color: #f6a81e;
  }

  .dashboard__eth {
    color: #5e62e6;
  }

  .dashboard__neo {
    color: #3ddb42;
  }

  .dashboard__ste {
    color: #21cbe6;
  }

  .dashboard__eos {
    color: #6d6a6a;
  }

  .dashboard__lit {
    color: #b7b3b3;
  }

  .dashboard__table-crypto {
    tbody td {
      padding: 17px 10px;
      white-space: nowrap;
    }

    .dashboard__table-crypto-chart {
      padding: 10px;
      width: 180px;
    }
  }

  .dashboard__place-order {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 900px) {
      flex-wrap: nowrap;
    }

    @media screen and (min-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1360px) {
      flex-wrap: nowrap;
    }
  }

  .dashboard__booking-card {
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 30px;
    padding-left: 30px;

    .dashboard__booking-card-progress-label {
      text-align: right;
      font-size: 14px;
      font-weight: 500;
    }

    .dashboard__trend-icon {
      fill: var(--color-light-gray);
      margin-right: 0px;
      margin-top: auto;
    }
  }

  .dashboard__booking-total-container {
    display: flex;
    justify-content: space-between;
  }
  .dashboard__booking-total-title {
    direction: ltr;
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    color: var(--color-accent);
  }

  .dashboard__booking-total-title--red {
    color: var(--color-red);
  }

  .dashboard__booking-total-title--green {
    color: var(--color-green);
  }

  .dashboard__booking-total-title--blue {
    color: var(--color-blue);
  }

  .dashboard__booking-total-description {
    text-align: left;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: var(--color-gray);
    margin-top: 3px;
    text-transform: uppercase;
    .theme-dark & {
      color: var(--color-dusty-white);
    }
  }

  .dashboard__booking-reservations-panel:not(.panel--collapse) {
    height: calc(100% - 138px);
  }

  .dashboard__booking-reservations {
    text-align: left;
    margin-top: -10px;

    &-title {
      font-size: 13px;
      color: var(--color-gray);
      .theme-dark & {
        color: var(--color-dusty-white);
      }
    }

    &-number {
      font-size: 48px;
      line-height: 34px;
      margin-top: 15px;
      margin-bottom: 10px;
      color: var(--color-gray);
      .theme-dark & {
        color: var(--color-dusty-white);
      }
    }

    &-chart {
      height: 180px;
      position: relative;

      @media screen and (min-width: 1400px) and (max-width: 1599px) {
        height: 230px;
      }

      .recharts-surface {
        width: 100%;
        height: 100%;
      }

      .recharts-legend-wrapper {
        width: 100% !important;
        display: block;
        position: static !important;
        @media screen and (min-width: 370px) {
          /* width: 115px !important */
          bottom: 0px;
        }
        @media screen and (min-width: 992px) {
          /* width: 105px !important */
          bottom: 70px;
        }
        @media screen and (min-width: 1020px) {
          /* width: 115px !important */
          bottom: -30px;
        }
        @media screen and (min-width: 1400px) {
          /* width: 125px !important */
          bottom: -55px;
        }
        @media screen and (min-width: 1800px) {
          /* width: 115px !important */
          bottom: -30px;
        }
      }

      .recharts-responsive-container {
        width: 100% !important;
        height: 100% !important;
        @media screen and (min-width: 1400px) and (max-width: 1599px) {
          height: calc(100% - 18px) !important;
        }
      }

      &-container {
        width: 100% !important;
        height: 100% !important;

        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        @media screen and (min-width: 992px) {
          width: 100% !important;
          height: 100% !important;
        }
        @media screen and (min-width: 1400px) and (max-width: 1599px) {
          flex-direction: column;
          align-items: baseline;
        }
      }

      &-legend {
        li {
          display: flex;
          align-items: baseline;
        }

        p {
          display: inline-block;
          margin: 0;
        }
      }
    }

    &-link {
      color: var(--color-blue);
      font-size: 12px;
      line-height: 1.5;
      position: absolute;
      bottom: 0;
      cursor: pointer;
      left: 0px;

      &-icon {
        width: 12px;
        height: 12px;
      }

      @media screen and (min-width: 1400px) {
        margin-top: 10px;
        display: inline-block;
        position: static;
      }
    }
  }

  .dashboard__booking-our-mission {
    text-align: left;
    height: 108px;
    background: var(--color-green);
    border-radius: 5px;
    padding: 15px 13px;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      height: 80px;
      width: 80px;
      top: calc(50% - 40px);
      opacity: 0.3;
      right: -23px;
      color: var(--color-white);
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 1.25;
      color: var(--color-white);
      max-width: 100%;
      @media screen and (min-width: 376px) and (max-width: 991px) {
        max-width: calc(100% - 60px);
      }
      @media screen and (min-width: 1200px) and (max-width: 1550px) {
        font-size: 14px;
      }
      @media screen and (max-width: 420px) {
        font-size: 14px;
      }
    }

    .dashboard__booking-our-mission-title {
      font-size: 24px;
      font-weight: 500;
      color: var(--color-white);
      margin-bottom: 4px;
      @media screen and (min-width: 1200px) and (max-width: 1399px) {
        font-size: 20px;
      }
    }
  }

  .dashboard__weekly-stat {
    hr {
      margin-bottom: 20px;
    }

    &-chart {
      display: flex;
      margin: 0 0 31px 0;
      flex-wrap: wrap;
      justify-content: space-around;

      .recharts-surface {
        width: 100%;
        height: 100%;
      }

      &-item {
        width: 110px;
        margin-bottom: 15px;

        @media screen and (max-width: 370px) {
          margin-left: 10px;
        }

        @media screen and (min-width: 1200px) {
          margin-left: 10px;
        }

        @media screen and (min-width: 1400px) {
          margin-left: 0px;
        }
      }

      &-pie {
        display: block;
        position: relative;
        text-align: center;
        height: 110px;

        .recharts-responsive-container {
          width: 100% !important;
        }
      }

      &-pie-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &-label {
      position: absolute;
      top: calc(50% - 10px);
      width: 100%;
      margin: 0;
      animation: label 1.5s ease-in;
      font-size: 24px;
      line-height: 24px;
    }

    &-info {
      margin-top: 10px;
      font-size: 13px;
      line-height: 1.38;
      text-align: center;
      p {
        color: var(--color-additional);
      }
    }
  }

  .dashboard__social-stat {
    &-item {
      text-align: left;
      display: flex;
      align-items: flex-end;
      width: 100%;

      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    &-title {
      width: 40%;
      font-size: 13px;
      line-height: 1.38;
      color: var(--color-additional);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-progress {
      width: 60%;
      margin-bottom: 5px;

      &.progress--wide {
        width: 80%;
      }

      .progress__label {
        text-align: right;
      }
    }
  }

  .dashboard__occupancy-table {
    text-align: left;

    td {
      font-size: 13px;
      padding: 5px;
    }

    td.td-head {
      color: var(--color-gray);
      .theme-dark & {
        color: var(--color-dusty-white);
      }
    }

    td.td-blue {
      font-weight: 500;
      color: var(--color-blue);
    }

    td.td-green {
      font-weight: 500;
      color: var(--color-green);
    }
    td.td-gray {
      color: var(--color-additional);
    }
  }

  .dashboard__place-order-form {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    form {
      padding: 20px 20px 30px 20px;
      border-radius: 5px;
      margin-top: 10px;
      background-color: var(--colorBorder);
    }

    .form__form-group-label {
      width: 50px;
    }

    .form__form-group-field {
      width: calc(100% - 50px);
    }

    .form__button-toolbar {
      margin-left: 0px;
      width: 100%;

      button {
        width: 100%;
      }
    }

    input {
      background-color: var(--bkgColor);
    }

    @media screen and (min-width: 900px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }

    @media screen and (min-width: 1200px) {
      width: 100%;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 1360px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }
  }

  .dashboard__place-order-form-subhead {
    position: absolute;
    font-size: 12px;
    top: 4px;
    right: 0px;
  }

  .dashboard__chart-pie.dashboard__chart-pie--crypto {
    @media screen and (min-width: 1200px) {
      .dashboard__chart-pie-container {
        padding-left: 0px;
      }

      .recharts-legend-wrapper {
        bottom: -110px !important;
      }
    }

    @media screen and (min-width: 1360px) {
      .recharts-legend-wrapper {
        bottom: 5px !important;
      }
    }
  }

  .mobile-app-widget {
    .mobile-app-widget__top-line {
      display: flex;

      .mobile-app-widget__total-stat {
        direction: ltr;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        margin-right: auto;
        line-height: normal;
      }
    }

    .mobile-app-widget__top-line--pink {
      .mobile-app-widget__total-stat {
        color: #ff4861;
      }
    }

    .mobile-app-widget__top-line--lime {
      .mobile-app-widget__total-stat {
        color: #b8e986;
      }
    }

    .mobile-app-widget__top-line--blue {
      .mobile-app-widget__total-stat {
        color: #48b5ff;
      }
    }

    .mobile-app-widget__top-line--turquoise {
      .mobile-app-widget__total-stat {
        color: #4ce1b6;
      }
    }

    .mobile-app-widget__title {
      text-align: left;
      text-transform: uppercase;
      position: relative;
      margin: 0 0 20px;

      h5 {
        font-size: 12px;
        color: var(--color-gray);
        font-weight: 500;
      }
    }

    .dashboard__trend-icon {
      margin: 0;
      height: 29px;
      min-width: 28px;
      fill: var(--color-light-gray);
    }

    .progress__label {
      font-size: 14px;
      font-weight: 500;
    }

    .progress__label {
      right: -7px;
    }

    .progress-bar {
      border-radius: 5px;
      background-color: unset;
    }
  }

  .dashboard__users-stat {
    .dashboard__trend-icon {
      fill: var(--color-light-gray);
      height: 24px;
      width: 24px;
      margin-right: 3px;
      min-width: 24px;
      margin-top: auto;
    }
  }

  .dashboard__current-users {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dashboard__current-users-chart {
      position: relative;
      width: 100%;

      div,
      svg {
        width: 100% !important;
        margin: 0 auto;
      }

      .dashboard__current-users-label {
        margin: 0;
        position: absolute;
        top: calc(50% + 10px);
        text-align: center;
        left: calc(50% - 30px);
        font-size: 38px;
      }
    }

    .dashboard__current-users-info {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      font-size: 13px;
      line-height: 1.54;
      color: var(--color-gray);

      p {
        margin: 0;
      }

      .dashboard__current-users-day {
        display: flex;
        flex-direction: column;
        align-items: center;

        & span:last-child {
          font-size: 16px;
          line-height: 1.25;
          font-weight: 500;
          color: var(--color-gray);
        }
      }
    }

    @media screen and (min-width: 1539px) {
      .dashboard__current-users-info {
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {
      .dashboard__current-users-chart {
        svg,
        div {
          height: 100px !important;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .dashboard__current-users-info {
        padding: 0 75px;
      }
    }
  }

  .dashboard__active-users-chart {
    overflow: hidden;
  }

  .dashboard__carousel {
    margin-bottom: -25px;

    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background: none;
      opacity: 0;
      pointer-events: none;
    }

    .dashboard__carousel-slide {
      text-align: left;
      border-radius: 5px;
      padding: 15px 13px;
      position: relative;
      overflow: hidden;
      background: var(--bkgColorBody);

      svg {
        position: absolute;
        height: 80px;
        width: 80px;
        top: calc(50% - 40px);
        opacity: 0.3;
        right: -20px;
        fill: var(--colorIcon);
      }

      p {
        color: var(--color-additional);
        margin: 0;
      }

      .dashboard__carousel-title {
        color: var(--color-red);
        font-size: 24px;
        font-weight: 500;
      }

      &.dashboard__carousel-slide--red {
        background: var(--color-red);

        p {
          color: white;
          opacity: 0.45;
        }

        svg {
          fill: var(--color-white);
        }

        .dashboard__carousel-title {
          opacity: 1;
        }
      }
    }
  }

  .row {
    position: relative;
    justify-content: center;

    .col-lg-12,
    .col-md-12,
    .col-12 {
      margin: 18px 0;
    }

    &._en_caja {
      @media screen and (max-width: 576px) {
        margin-top: 3rem;
      }
    }
  }

  .cont_btns_caja {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: 100%;
  }

  span:has(> .cont_btns_caja) {
    align-self: flex-end;
  }

  .download-button,
  .close-button {
    padding: 4px 16px 4px 8px;
    margin: 0;
    width: max-content;
    min-width: 160px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    transition: all 200ms;
    color: var(--textColor);
    background: var(--bkgColor);

    &:active {
      transform: scale(0.95);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    svg {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    &._marco {
      margin: 22px auto 0;
      float: right;
      border-radius: 8px;
      color: var(--textColor);
      border: 1px solid var(--textColor);
      background: var(--bkgInbox);
    }

    &._flotante {
      position: absolute;
      top: 0;
      right: 0;
      height: 34px;
    }

    &._flotante_alone {
      position: absolute;
      top: 0;
      right: 0;
      height: 34px;
    }

    &._deshabilitado {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media screen and (max-width: 576px) {
      padding: 8px 24px 8px 12px;
      font-size: 16px;
      margin: 12px 0 8px;

      &._flotante {
        top: 32px;
        right: 18px;
        height: 38px;
      }

      &._flotante_alone {
        top: 0;
        right: 18px;
        height: 38px;
      }
    }
  }

  .cash-button {
    padding: 4px 16px 4px 8px;
    margin: 6px 16px;
    width: max-content;
    min-width: 150px;
    height: auto;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: all 200ms;
    color: var(--textColor);
    background-color: var(--bkgInbox);
    border: 1px solid var(--textColor);

    &:active {
      transform: scale(0.95);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    svg {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    &._flotante {
      position: absolute;
      top: 0;
      right: 160px;
      height: 34px;
    }

    &._deshabilitado {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media screen and (max-width: 576px) {
      min-width: 130px;
      padding: 8px 24px 8px 12px;
      font-size: 18px;
      margin: 20px 12px 0;
    }
  }

  .content_reportes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 0;

    .btn_lazy_load {
      display: block;
      width: max-content;
      padding: 0.4rem 2rem;
      margin: 1rem auto 0;
      font-size: 1.2rem;
      border-radius: 12px;
      color: var(--textColor);
      border: 1px solid var(--textColor);
      background: var(--bkgColor);

      svg {
        margin-top: -4px;
        width: 1.2rem;
        height: 1.2rem;
        transform: scale(1.7);
      }

      @media screen and (max-width: 576px) {
        width: 90%;
        padding: 0.5rem 2rem;
      }
    }

    .h4_lazy_load {
      width: 100%;
      text-align: center;
      font-size: 1.1rem;
      white-space: pre-line;

      &._margin_top {
        margin-top: 1rem;
      }
    }

    @media screen and (max-width: 576px) {
      padding-top: 0;
      padding-right: 8px;
    }
  }

  .content_select {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 16px;
    padding: 0 12px;

    select {
      width: 160px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 6px 12px;
      font-size: 14px;
      color: #333;
      background-color: #fff;
      transition: all 200ms;
      color: var(--textColor);
      background: var(--bkgColor);
    }

    .cont_calendar {
      margin: 14px 0 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .calendar {
        position: relative;
        width: 120px;
        margin: 0 8px;
        cursor: pointer;

        svg {
          position: absolute;
          top: 1px;
          right: 6px;
          width: 18px;
          color: var(--textColor);
        }

        input {
          width: 100%;
          height: 25px;
          padding: 8px 8px;
          border: solid 1px rgb(200, 200, 200);
          border-radius: 4px;
          color: var(--textColor);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width: 576px) {
      margin-top: 110px;

      &._alone {
        margin-top: 40px;
      }

      select {
        width: 185px;
        font-size: 16px;
        padding: 8px 10px;
      }

      .cont_calendar {
        .calendar {
          width: 140px;

          svg {
            width: 24px;
            height: 24px;
            top: 8px;
            right: 6px;
          }

          input {
            padding: 18px 8px;
            font-size: 16px !important;
          }
        }
      }
    }
  }

  &._caja {
    ._titulo {
      font-weight: 500;
      font-size: 1.6rem;
      margin: 20px 0 12px;
      white-space: pre-line;
    }

    ._precio {
      display: inline-block;
      font-weight: bold;
      font-size: 1.8rem;
      margin: 20px 0 12px;
      padding: 0 18px;
      text-align: right;

      span {
        display: inline-block;
        padding: 0 0 1rem;
        font-size: 1rem;
        font-weight: normal;
        margin-right: 12px;

        &.fija {
          position: relative;
          width: 10rem;
          padding-left: 12px;
          text-align: left;

          i {
            position: absolute;
            bottom: 0;
            font-size: 0.7rem;
          }

          @media screen and (max-width: 576px) {
            width: 45%;
          }
        }
      }
    }
  }

  &.container {
    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      .row {
        width: 100%;
        margin: 0 auto;
        margin-right: -0.25rem;

        &.recalc_left {
          width: calc(100% + 1rem);
          margin-left: -0.5rem;
        }
      }
    }
  }
}

.col_static {
  max-width: 500px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.col_table {
  width: 80%;
  margin-top: 12px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.line_h {
  display: block;
  box-sizing: content-box;
  width: 100%;
  height: 1px;
  color: inherit;
  opacity: 0.25;
  margin: 0;
  background-color: var(--textColor);
}

.content_icons {
  padding: 3rem 6rem;

  h2 {
    display: block;
    text-align: center;
  }

  p {
    display: inline-block;
    text-align: center;
    margin: 1rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.container_error {
  display: flex;
  flex-flow: row nowrap;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 2rem;
  border-radius: 16px;
  transition: all 0.3s;
  background-color: var(--bkgBodyContra);

  p {
    display: block;
    width: 50%;
    padding: 15% 4rem 0 0;
    font-size: 1rem;

    span {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    b {
      margin-right: 0.4rem;
    }

    a {
      color: var(--colorLinck);
    }
  }

  img {
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    padding: 3rem 6rem;
  }

  @media screen and (max-width: 620px) {
    padding: 0 1rem 2rem;
    flex-wrap: wrap-reverse;

    p {
      width: 100%;
      margin-top: -2rem;
      padding: 0 1rem 0;
      font-size: 1.2rem;

      span {
        margin-top: 2rem;
        margin-bottom: 2.2rem;
        font-size: 1.5rem;
      }

      a {
        font-size: 1.2rem;
      }
    }

    img {
      width: 100%;
    }
  }
}
