.form {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  input,
  textarea {
    width: 100%;
    padding: 8px 10px;
    font-size: 12px;
    transition: border 0.3s;
    color: var(--textPrimary) !important;
    background: var(--bkgInbox);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--textPrimary) !important;
      -webkit-box-shadow: 0 0 0px 50px var(--bkgInbox) inset;
    }

    &::-webkit-input-placeholder {
      color: var(--color-additional);
    }
    &::-moz-placeholder {
      color: var(--color-additional);
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: var(--color-additional);
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: var(--color-additional);
    }

    &[disabled] {
      background: var(--bkgColorBody);

      &:focus,
      &:active {
        border-color: var(--colorBorder);
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: var(--color-accent);
    }

    @media screen and (max-width: 576px) {
      font-size: 18px;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 576px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;
      border: 1px solid var(--colorFieldsBorder);
    }

    .Select-placeholder,
    .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {
      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: var(--color-blue);
      }

      .Select-value-label {
        color: var(--textPrimary) !important;
      }

      .Select-value-icon {
        border-color: var(--color-blue);
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;
      border: 1px solid var(--colorBorder);
      background: var(--bkgColor);
    }

    @keyframes open {
      0% {
        max-height: 0;
      }
      100% {
        max-height: 200px;
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;
      background: var(--bkgColor);
      color: var(--textPrimary);

      &.is-focused {
        background: var(--colorHover);
      }
    }

    &.is-focused,
    &.is-focused:not(.is-open) {
      .Select-control {
        border-color: var(--color-accent);
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }

  @media screen and (max-width: 576px) {
    font-size: 18px;

    input,
    select,
    textarea {
      padding: 8px 10px !important;
      font-size: 16px !important;
    }

    .form__form-group-label {
      font-size: 16px !important;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  color: var(--textPrimary) !important;
  background: var(--bkgInbox);
  border: 1px solid var(--fieldsBorder);

  &.input_radius {
    border-radius: 8px;
    /*  overflow: hidden */

    .input {
      width: 100%;
    }
  }

  .radius_right {
    border-radius: 0 8px 8px 0;
  }

  input,
  textarea {
    border: none;
    /*    border: 1px solid var(--colorFieldsBorder) */
  }
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: var(--textPrimary);

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  position: absolute;
  right: 0;
  padding: 6px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0 7px 7px 0;
  border: none;
  background-color: transparent;
  z-index: 5;
  /*   background: var(--colorFieldsBorder);*/
  /*   border: 1px solid var(--colorFieldsBorder);*/

  svg {
    fill: var(--color-additional);
    width: 18px;
    height: 18px;
    transition: all 0.3s;

    @media screen and (max-width: 576px) {
      width: 24px;
      height: 24px;
    }
  }

  &.active {
    background: var(--color-accent);
    border: 1px solid var(--color-accent);

    svg {
      fill: #ffffff;
    }
  }

  @media screen and (max-width: 576px) {
    margin-top: 3px;
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    border: 1px solid var(--colorFieldsBorder);
    color: var(--textPrimary);

    &:hover {
      background: var(--colorFieldsBorder);
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  height: 32px;
  padding: 4px 6px 6px;
  border-radius: 8px 0 0 8px;
  background: var(--colorFieldsBorder);
  border: 1px solid var(--colorFieldsBorder);

  svg {
    fill: var(--color-additional);
    width: 18px;
    height: 18px;
    transition: all 0.3s;

    @media screen and (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
  }
}

.form__form-group-description {
  font-size: 10px;
  color: var(--color-additional);
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.input {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  input {
    padding-right: 22px !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  svg {
    position: absolute;
    right: 0;
    width: 26px !important;
    height: 26px !important;
    padding: 4px;
    cursor: pointer;
    transition: all 200ms;
    color: var(--textPrimary) !important;

    &:active {
      transform: scale(0.9);
    }

    &.scan_icon {
      width: 42px;
      height: 42px;
    }
  }

  ._float_list {
    position: absolute;
    top: 30px;
    width: 100%;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    overflow: auto;
    pointer-events: all;
    scrollbar-color: rgb(160, 160, 160) rgba(0, 0, 0, 0);
    scrollbar-width: 10px;
    z-index: 90;
    background-color: var(--bkgColorList);
    border: 1px solid var(--colorBorderList);

    &._on_top {
      top: auto;
      bottom: 30px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textPrimary);
    }

    li {
      width: 100%;
      min-height: 24px;
      display: block;
      padding: 6px 6px;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      white-space: nowrap;
      background: none;
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &._selected {
        background-color: var(--colorSelectList);

        &:hover {
          background-color: color-mix(
            in srgb,
            var(--colorSelectList),
            black 30%
          );
        }
      }

      &:hover {
        background-color: var(--colorHoverList);
      }

      a {
        color: var(--textList);
      }
    }
  }

  @media screen and (max-width: 576px) {
    svg {
      width: 28px;
      height: 28px;
    }

    ._float_list {
      li {
        padding: 8px 6px;
        font-size: 14px;
      }
    }
  }
}

._sign_up {
  width: calc(100% + 100px);
  transform: translateX(-46px);

  label {
    margin: 12px 0 0;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 6px 8px;
    font-size: 14px;
    border-radius: 4px;
    color: var(--textPrimary) !important;
    background: var(--bkgInbox);
    border: 1px solid var(--fieldsBorder);
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  textarea {
    resize: none;
  }

  .input {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  ._in_row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 576px) {
      display: block;
    }
  }

  ._in_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    padding: 4px 8px;

    @media screen and (max-width: 576px) {
      margin-right: 0;
      margin-left: 0;
      padding: 4px 0;
    }
  }

  ._toglle_pass {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    svg {
      position: absolute;
      right: 4px;
      padding: 2px;
      cursor: pointer;
      color: rgb(149, 136, 134);
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    transform: none;
  }
}
