.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  display: block;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 70px 16px 30px;
  transition: all 0.3s;
  background: var(--bkgColorBody);

  @media screen and (max-width: 576px) {
    padding: 70px 6px 30px;
  }
}

.layout {
  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  margin: 8px 16px 12px;
  padding: 20px;
  border-radius: 8px;
  background: var(--bkgColor);

  p {
    font-size: 0.9rem;
    text-align: center;
  }

  i {
    font-size: 0.7rem;
    opacity: 0.5;
  }

  a {
    white-space: nowrap;
    text-decoration: none;
    color: var(--colorLinck);
  }

  div:first-child {
    width: 100%;

    @media screen and (max-width: 576px) {
      margin-top: 1rem;
    }
  }

  .enlaces {
    width: 12rem;
    margin: 0 3rem 0 1rem;
    padding-left: 1rem;
    padding-right: 2rem;
    border-left: 1px solid var(--colorFieldsBorder);

    h5 {
      margin: 0 0 1rem;
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;
    }

    a {
      width: 50%;
      padding: 0 1rem 0.4rem;
      display: block;
      font-size: 0.8rem;
      text-align: center;
    }

    @media screen and (max-width: 576px) {
      border-left: none !important;

      h5 {
        font-size: 1.1rem;
      }

      a {
        padding: 0.5rem 1rem;
        font-size: 1rem;
      }
    }

    @media screen and (min-width: 1024px) {
      margin-right: 5rem;
    }
  }

  .logo_help {
    display: block;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    color: rgb(240, 240, 240);
    border: 1px solid rgb(240, 240, 240);
    background: var(--bkgColorCorporate);
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      bottom: 1.4px;
      right: 1.4px;

      width: 16px;
      height: 16px;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right: 1px solid rgb(240, 240, 240);
      border-bottom: 1px solid rgb(240, 240, 240);
      background: var(--bkgColorCorporate);
      /*   clip-path: polygon(46% 65%, 100% 0, 100% 100%, 0% 100%); */
      z-index: 1;
    }

    svg {
      display: block;
      position: relative;
      width: 32px;
      height: 32px;
      margin: 6px auto 0;
      z-index: 2;
    }

    h5 {
      display: block;
      position: relative;
      width: 100%;
      font-size: 0.7rem;
      text-align: center;
      color: rgb(240, 240, 240);
      z-index: 2;
    }
  }

  .failover_alert {
    display: block;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    margin: 0;
    padding: 0.4rem 1rem;
    width: max-content;
    max-width: calc(100% - 60px);
    border-radius: 8px;
    color: rgb(255, 255, 0);
    border: 2px solid rgb(255, 255, 0);
    background: rgb(255, 0, 0);
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0.4rem;
      width: 2rem;
      height: 2rem;
      margin: 6px auto 0;
    }

    h5 {
      display: block;
      position: relative;
      width: calc(100% - 2rem);
      margin-left: 2rem;
      font-size: 0.7rem;
      text-align: left;
      font-weight: bold;
      color: rgb(255, 255, 0);

      i {
        opacity: 1;
        font-weight: normal;
      }
    }

    @media screen and (max-width: 400px) {
      /*    bottom: 5rem */
      max-width: calc(100% - 30px);
    }
  }

  .update_alert {
    display: block;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    margin: 0;
    padding: 0.4rem 1rem;
    width: max-content;
    max-width: calc(100% - 60px);
    border-radius: 8px;
    color: rgb(255, 0, 0);
    border: 2px solid rgb(255, 0, 0);
    background: yellow;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0.4rem;
      width: 2rem;
      height: 2rem;
      margin: 6px auto 0;
    }

    h5 {
      display: block;
      position: relative;
      width: calc(100% - 2rem);
      margin-left: 2rem;
      font-size: 0.7rem;
      text-align: left;
      font-weight: bold;
      color: rgb(255, 0, 0);

      i {
        opacity: 1;
        font-weight: normal;
        color: rgb(0, 100, 255);
      }
    }

    @media screen and (max-width: 400px) {
      /*    bottom: 5rem */
      max-width: calc(100% - 30px);
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
    width: calc(100% - 16px);
    margin: 8px 8px 12px;
  }
}
