.card {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  /*  overflow: hidden */

  &.card--not-full-height {
    margin: 0 auto;
    max-width: 500px;
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;
  background-color: var(--bkgColor);

  &.width_static {
    width: 100%;
    min-width: 250px;
    padding: 0;
  }

  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    position: relative;
    font-size: 1rem;
    padding-left: 2.3rem;

    svg {
      position: absolute;
      left: 0.7rem;
      top: -0.1rem;
    }

    @media screen and (max-width: 576px) {
      padding-right: 2rem;
    }
  }

  button.cash-button {
    margin: 18px 10% -24px;
    color: var(--textColor);
    background-color: var(--bkgInbox);
    border: 1px solid var(--textColor);

    @media screen and (max-width: 576px) {
      margin-left: 50%;
      transform: translateX(-50%);

      &:active {
        transform: scale(0.95) translateX(-52%);
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.squared-corner-theme {
  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}
