p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--textColor);
}

p {
  margin: 10px 0 0 0;

  &:first-child {
    margin: 0;
  }
}

h1 {
  font-size: 36px;
  line-height: 48px;

  &.subhead {
    font-size: 30px;
    color: var(--color-additional);
    line-height: 36px;
  }
}

h2 {
  font-size: 30px;
  line-height: 36px;

  &.subhead {
    font-size: 24px;
    color: var(--color-additional);
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;

  &.subhead {
    font-size: 18px;
    color: var(--color-additional);
    line-height: 24px;
  }

  &._title {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    width: min-content;
    white-space: nowrap;
    padding: 12px 16px 8px;
    border-radius: 12px;
    z-index: 100;
    background-color: var(--bkgColor);

    &._in_filters {
      margin-bottom: -32px;

      @media screen and (max-width: 860px) {
        margin-bottom: 0;
      }
    }

    &._in_page {
      margin: 1.3rem 0 0.8rem;
      background-color: var(--bkgColorBody);

      i {
        font-size: 1.1rem;
      }

      span {
        display: inline;
        font-size: 1rem;
        font-weight: normal;
      }
    }

    &._with_shadow {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    }

    @media screen and (max-width: 576px) {
      width: auto;
      max-width: 100%;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      font-size: 1.4rem;

      &._in_page {
        margin: 0 0 3.5rem;

        span {
          display: block;
        }
      }
    }
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;

  &.subhead {
    font-size: 12px;
    color: var(--color-additional);
    line-height: 16px;
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    color: var(--color-additional);
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    color: var(--color-additional);
    line-height: 10px;
  }
}

.bold-text {
  font-weight: 700;
  color: var(--textColor);
}

.typography--inline {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-top: auto;
    margin-right: 15px;
  }
}

.typography-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }
}

blockquote {
  font-size: 13px;
  font-style: italic;
  border-left: 3px solid var(--color-accent);
  margin-bottom: 0;
  padding-left: 10px;
}

.highlight {
  background-color: var(--color-accent);
  color: #ffffff;
}

.red-text {
  color: #ad4444;
}

.page-title {
  font-weight: 500;
  font-size: 1.6rem;
  margin: 20px 0 12px;

  i {
    font-size: 1.1rem;
  }

  span {
    display: inline;
    font-size: 1rem;
    font-weight: normal;

    @media screen and (max-width: 576px) {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 30px;
  }
}

.page-subhead {
  margin-bottom: 20px;

  &.subhead {
    font-size: 14px;
    opacity: 0.7;
  }
}

::selection {
  color: #ffffff;
  background: var(--colorSelect);
}
