.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 109;
  height: 100vh;
  width: 240px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 60px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background: var(--bkgColorCorporate);

  a {
    display: block;
  }

  &.sidebar--show {
    transform: translateX(0);

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {
  .sidebar__link {
    background-color: rgb(80, 133, 194);

    &:before,
    &:after {
      opacity: 1;
    }
  }
}

.sidebar__scroll {
  width: 240px;

  & > div {
    height: calc(100vh - 60px);
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  &:last-child {
    border: none;
  }

  hr {
    border-top: 1px solid rgb(255, 255, 255);
  }
}

.sidebar__link {
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  /*  color: var(--textColor); */

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: var(--color-accent);
    opacity: 0;
    transition: all 0.3s;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: var(--color-accent);
    opacity: 0;
    transition: all 0.3s;
  }

  svg {
    position: absolute;
    left: 12px;
    width: 24px;
    height: 24px;
    color: var(--color-white);
  }

  p {
    position: absolute;
    left: 43px;
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-white);
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(80, 133, 194, 0.8);
    /*   background-color: var(--colorHover); */

    &:before,
    &:after {
      opacity: 1;
    }
  }

  @media screen and (max-width: 576px) {
    height: 50px;
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 16px;
  line-height: 1;
  position: relative;
  display: flex;
}

.sidebar__link-icon {
  margin-right: 12px;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1;
  color: var(--color-white);
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding-left: 53px;

      p {
        left: 53px;
      }
    }
  }

  .sidebar__link {
    padding-left: 43px;

    &:hover {
      background-color: rgba(80, 133, 194, 0.7);
    }
  }

  .sidebar__link {
    display: block;
  }
}

.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: var(--color-white);
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: var(--color-red);
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {
  &.sidebar__category-wrap--open {
    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: var(--color-red);
}

@media screen and (min-width: 1025px) {
  .sidebar {
    display: none;
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }

  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}
