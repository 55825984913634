.comun {
  width: 100%;
  min-height: 60vh;
  margin: 12px auto 0;
  padding: 20px;
  border-radius: 8px;
  background: var(--bkgColor);

  .icono {
    svg {
      width: 50px;
      height: 50px;
      fill: var(--textBlue);
      color: var(--textBlue);
    }

    &._large {
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  h4 {
    width: 100%;
    margin-top: 6px;
    font-size: 16px;
    text-align: center;
    color: var(--textGray);
  }

  hr {
    width: 50%;
    margin: 0 auto;

    @media screen and (max-width: 576px) {
      width: 80%;
    }
  }

  .comun_tool_bar {
    position: relative;
    margin: 0 0 6px;
    padding: 4px 0;
    display: flex;
    direction: rtl;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
  }

  .option_up {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    ._btn_up {
      width: 100%;
      max-width: 150px;
      margin: 12px 8px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      transition: all 400ms;

      @media screen and (min-width: 501px) {
        &:hover {
          box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.338);
        }

        &:active {
          transform: scale(0.92);
        }
      }
    }

    @media screen and (max-width: 576px) {
      ._btn_up {
        width: 100%;
        max-width: 120px;
        margin: 8px 6px;

        &:active {
          transform: scale(0.92);
        }
      }
    }
  }

  .option_down {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    ._btn_down {
      width: 100%;
      max-width: 180px;
      margin: 8px 40px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border: 1px solid rgba(112, 112, 112, 0.224);
      border-radius: 8px;
      cursor: pointer;
      transition: all 400ms;

      @media screen and (min-width: 501px) {
        &:hover {
          box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.338);
        }

        &:active {
          transform: scale(0.92);
        }
      }

      @media screen and (max-width: 576px) {
        width: 40%;
        margin: 12px 8px;
        padding: 12px 8px;

        &:active {
          transform: scale(0.92);
        }
      }
    }
  }

  .comun_content {
    margin-top: 2rem;

    ._sub_titulo {
      margin: 0 1rem 2rem;
      font-size: 1rem;
      font-weight: 300;
    }

    table {
      tr {
        &.row_help {
          svg {
            &:first-child {
              width: 2.5rem;
              height: 2.5rem;
              padding: 0.1rem 0.6rem 0.6rem 0.1rem;
            }

            &:nth-child(2) {
              width: 1.3rem;
              height: 1.3rem;
              padding: 0;
              margin-left: -1.5rem;
              margin-right: 0.7rem;
              margin-bottom: -0.8rem;
              border-radius: 50%;
              background: var(--bkgColor);
            }
          }

          td,
          th {
            width: 100%;
            max-width: 100% !important;
            font-size: 1rem;
            font-weight: bold;
            color: var(--textAdditional);
          }

          td {
            display: inline-block;
            padding: 0 0.6rem 0 0.2rem;
            margin: 6px 0;
            border-radius: 12px;
            cursor: pointer;
            pointer-events: none;
            border: 1px solid var(--textAdditional);
          }

          p {
            padding-left: 0.2rem;
            display: inline-block;
            font-weight: 200;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  &._terminos {
    width: 90%;
    max-width: 1280px;
    padding: 3rem 5rem 6rem;

    h2 {
      display: block;
      margin: 1rem 0 2rem;
      font-size: 1.7rem;
    }

    h3 {
      display: block;
      margin: 1rem 0 0.3rem;
      font-size: 1.3rem;
    }

    p {
      display: block;
      margin: 0 0 0.3rem;
      font-size: 1rem;
      color: var(--textColor);

      &.last-updated {
        font-style: italic;
        color: #7f8c8d;
        font-size: 0.8rem;
        margin: 0 0 0.9rem;
      }

      a {
        color: var(--colorLinck);
      }
    }

    ul {
      padding-left: 1rem;
      list-style: inside;

      li {
        color: var(--textColor);
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 2rem 1rem 3rem;
    }
  }
}

.search_bar {
  position: relative;
  width: 100%;
  max-width: 300px;
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: left;

  input {
    width: 300px;
    padding: 8px 35px 8px 8px;
    margin: 0;
    font-size: 14px;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--colorInput);
    background: var(--bkgColor);
    color: var(--textColor);
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.4rem;
    height: 1.4rem;
    margin: 0.4rem 0.2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 400ms;
    fill: var(--textColor);
    color: var(--textColor);

    &.icon_close {
      padding: 2px;
      color: var(--textColor);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  ._float_list {
    position: absolute;
    top: 30px;
    width: 100%;
    max-width: 300px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    overflow: auto;
    pointer-events: all;
    scrollbar-color: rgb(160, 160, 160) rgba(0, 0, 0, 0);
    background-color: var(--bkgColorList);
    border: 1px solid var(--colorBorderList);
    scrollbar-width: 10px;
    z-index: 90;

    &._on_top {
      top: auto;
      bottom: 30px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bkgColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid var(--colorThumb);
      background-color: var(--colorThumb);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--textColor);
    }

    li {
      width: 100%;
      min-height: 24px;
      display: block;
      padding: 6px 6px;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      white-space: nowrap;
      background: none;
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      color: var(--textList);

      &._selected {
        background-color: var(--colorSelectList);

        &:hover {
          background-color: color-mix(in srgb, var(--colorHoverList), black 10%);
        }
      }

      &:hover {
        background-color: var(--colorHoverList);
      }
    }
  }

  &._in_list {
    padding: 0;
    justify-content: center;

    input {
      width: 100%;
      margin: 0;
      border-radius: 8px 8px 0 0;
    }
  }

  @media screen and (max-width: 576px) {
    justify-content: center;
    margin: 8px auto 0;

    input {
      width: 100%;
      font-size: 16px !important;
      padding: 10px 35px 10px 8px;
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.3rem 0.2rem 0;
      width: 2rem;
      height: 2rem;

      &.icon_close {
        padding: 4px;
      }
    }

    ._float_list {
      max-width: 90%;

      li {
        padding: 8px 6px;
        font-size: 14px;
      }
    }
  }
}

.cont_search_bar {
  position: relative;
  margin: -2rem 0 6px;
  padding: 4px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: right;
  align-items: center;
  border-radius: 8px;
  z-index: 18;
  background: var(--bkgColor);

  .fliter_top {
    width: 34px;
    height: 34px;
    cursor: pointer;
    fill: var(--textBlue);
    color: var(--textBlue);
  }

  .search_bar {
    width: 100%;
    max-width: 300px;
    margin: 2px 8px;

    svg {
      width: 24px !important;
      height: 24px !important;
      top: 0;
      right: 0;
      padding: 0;
      fill: var(--textColor);
      color: var(--textColor);
    }

    button {
      width: 150px;
      height: 2rem;
      position: absolute;
      right: 6px;
      bottom: -2.3rem;
      font-size: 1rem;
      border-radius: 8px;
      background-color: var(--bkgColor);
      color: var(--textColor);
      border: 1px solid var(--textColor);
      transition: all 200ms;
      box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.3);

      &:active {
        transform: scale(0.95);
      }
    }
  }

  & ~ div {
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    margin-top: 0;
    margin-bottom: 8px;

    .fliter_top {
      margin: 0 0.6rem;
    }

    .search_bar {
      max-width: 100%;
      margin: 2px 8px;

      .input {
        width: 100%;

        input {
          padding-right: 34px !important;
        }
      }

      svg {
        width: 32px;
        height: 32px;
        top: 3px;
        right: 4px;
        padding: 2px;
      }

      button {
        top: -2.3rem;
        bottom: auto;
        right: 0;
      }
    }
  }
}
