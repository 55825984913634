.pickup {
  width: 100%;
  padding: 18px;
  position: relative;
  border-radius: 8px;
  background: var(--bkgColor);

  .pickup_container {
    width: 100%;
    display: block;

    h5 {
      width: 100%;
      font-size: 16px;
      text-align: center;
      margin: 20px 0 40px;

      &._collect {
        position: sticky;
        top: 50px;
        width: min-content;
        margin: 0 auto;
        padding: 22px 12px 8px;
        text-align: left;
        white-space: nowrap;
        color: rgb(255, 30, 30);
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        z-index: 19;

        @media screen and (max-width: 1170px) {
          top: 100px;
        }

        @media screen and (max-width: 480px) {
          width: auto;
          max-width: 100%;
          white-space: normal;
        }
        @media screen and (max-width: 380px) {
          top: 110px;
        }
      }
    }

    h6 {
      width: 100%;
      margin: 0;
      font-size: 14px;
      text-align: center;

      &._title {
        margin: 0 0 12px;
        font-weight: bold;
      }

      b {
        display: block;
        margin-bottom: 6px;
      }
    }

    label {
      width: 100%;
      margin-right: 6px;
      color: rgb(100, 103, 119);
      font-size: 12px;
      display: block;
      color: var(--textGray);

      &._marco {
        min-width: 100px;
        margin-top: 4px;
        margin-bottom: 16px;
        padding: 6px 8px;
        color: rgb(149, 136, 134);
        border: 1px solid rgba(68, 47, 47, 0.224);
        border-radius: 4px;
      }

      &.check_collect {
        width: min-content;
        margin: 6px auto 0;
        display: flex;
        flex-flow: nowrap;
        white-space: nowrap;
        cursor: pointer;

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          min-width: 16px;
          margin: 0 8px;
          padding: 0;
          cursor: pointer;
        }

        &._legend {
          margin: -14px auto 0;
          padding-right: 8px;
          background-color: var(--bkgColor);
        }
      }

      &.label_collect {
        width: 160px;
        margin: 24px auto 0;
        padding-left: 12px;
        display: flex;
        flex-flow: nowrap;
        white-space: nowrap;
        cursor: pointer;

        & + .input {
          max-width: 160px;
          margin: 0 auto;
          padding: 0;

          input {
            min-width: auto;
          }
        }
      }
    }

    select,
    textarea,
    input {
      width: 100%;
      min-width: 180px;
      padding: 8px 8px 6px;
      font-size: 0.8rem;
      border-radius: 4px;
      outline: none;
      color: var(--textColor);
      background: var(--bkgInbox);
      border: 1px solid var(--colorInput);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: var(--textColor) !important;
        -webkit-box-shadow: 0 0 0px 50px var(--bkgInbox) inset;
      }

      &::placeholder {
        text-transform: lowercase;
        color: var(--textColor);
        opacity: 0.5;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      @media screen and (max-width: 576px) {
        font-size: 1rem;
      }
    }

    input[type="date"],
    input[type="time"] {
      min-width: 116px;
    }

    .react-datepicker-wrapper,
    .rc-time-picker {
      input {
        width: 100px;
        min-width: 100px;
      }
    }

    textarea {
      resize: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }

    fieldset {
      width: max-content;
      max-width: 400px;
      margin: 12px auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid var(--fieldsetBorder);

      &.coment_and_files {
        max-width: 1024px;
      }

      label {
        margin-bottom: 6px;
      }

      legend {
        float: none;
        width: max-content;
        padding: 0 6px;
        margin: 0 4px;
        font-size: 0.8rem;
        line-height: inherit;
        color: var(--textColor);

        span {
          padding: 3px 8px;
          border-radius: 6px;
        }

        @media screen and (max-width: 576px) {
          font-size: 1rem;
        }
      }

      .info_static {
        min-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;

        label,
        input {
          width: 220px;
        }
      }

      &.new_features {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 18px !important;
        border-radius: 12px;
        border: 1px solid var(--fieldsetBorder);

        &._no_border {
          border: none;
        }

        h5 {
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
          text-align: left;
          z-index: 1;

          svg {
            top: -8px;
            right: 2px;
            z-index: 5;
          }
        }

        ._a_resalt {
          position: absolute;
          max-width: 100%;
          margin-top: 4px;
          text-decoration: none;
          font-size: 12px;
          color: #958886;
        }

        .f_row {
          .features_options {
            textarea,
            input {
              min-width: auto;
            }

            .dropzone__input {
              min-width: 250px;
              min-height: 100px;
              border-radius: 4px;
              color: var(--textColor);
              background: var(--bkgInbox);
              border: 1px solid var(--colorInput);
              /* border: 1px solid rgba(112, 112, 112, 0.224); */
            }
          }
        }

        .dropzone__drop-here p {
          width: 70%;
          height: min-content;
          margin: 0;
          padding: 0;
          font-size: 12px;
          color: #999999;
          background: none;
          border: none;
          text-align: left;
          cursor: auto;
          box-shadow: none;
          pointer-events: none;
        }

        @media screen and (max-width: 576px) {
          width: 100%;

          h5 {
            svg {
              right: 4px;
            }
          }

          .f_row {
            width: 100%;
          }
        }
      }

      &._metodos_pago {
        display: flex;
        max-width: calc(250px + 2rem);
        flex-direction: column;
        margin: 0.7rem -1rem 0.8rem -0.5rem;
        border-radius: 8px;

        legend {
          font-size: 0.8rem;

          &._camel_case {
            text-transform: capitalize;
          }
        }

        input {
          margin-left: -0.3rem;
          min-width: auto !important;
        }
      }

      &._redes_sociales {
        position: relative;
        display: flex;
        max-width: 180px;
        flex-direction: column;
        margin: 0.7rem 0.4rem 0.8rem;
        border-radius: 8px;

        legend {
          font-size: 0.8rem;
        }

        label {
          margin: 0.9rem 0 0;
        }

        input {
          min-width: auto !important;
        }

        select {
          min-width: 160px;
        }
      }
    }

    .pickup_column {
      display: flex;
      flex-direction: column;
      margin: 18px;

      textarea,
      .dropzone__input {
        min-width: 250px;
        min-height: 100px;
        border-radius: 4px;
        color: var(--textColor);
        background: var(--bkgInbox);
        border: 1px solid var(--colorInput);
        /* border: 1px solid rgba(68, 47, 47, 0.224); */
      }

      label.cont_check {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin: 6px 8px;
        padding: 2px 12px 2px 8px;
        width: min-content;
        font-size: 1rem;
        white-space: nowrap;
        border-radius: 6px;
        cursor: pointer;
        transition: all 100ms;
        border: 1px solid var(--colorInput);
        background-color: var(--bkgInbox);
        color: var(--textColor);

        &:active {
          transform: scale(0.95);
          background-color: var(--bkgInbox);
          filter: brightness(90%);
        }

        @media screen and (max-width: 576px) {
          margin: 8px 8px;
          padding: 4px 14px 4px 8px;
          font-size: 1.2rem !important;
        }
      }

      .input {
        width: 100%;
      }

      input[type="checkbox"] {
        width: 14px;
        min-width: 14px !important;
        height: 14px;
        margin: 0 8px 0 0;
        pointer-events: none;

        @media screen and (max-width: 576px) {
          width: 18px;
          height: 18px;
        }
      }

      select,
      textarea,
      input[type="password"],
      input[type="tel"],
      input[type="email"],
      input[type="text"] {
        width: 100%;
        min-width: 180px !important;
        padding: 8px 8px 6px;
        font-size: 12px;
        border-radius: 4px;
        outline: none;
        border: 1px solid var(--colorInput);
        background: var(--bkgInbox);
        color: var(--textColor);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: var(--textColor) !important;
          -webkit-box-shadow: 0 0 0px 50px var(--bkgInbox) inset;
        }

        &::placeholder {
          text-transform: lowercase;
          color: var(--textColor);
          opacity: 0.5;
        }
      }

      textarea {
        min-height: 60px !important;
        resize: none;
      }

      button {
        margin-top: 18px;
      }

      datalist {
        option {
          &._opt_sel {
            background-color: rgb(88, 88, 88);
          }
        }
      }

      .text_clipboard {
        margin: 16px 0 12px;
        font-size: 1.3rem;

        svg {
          width: 32px;
          height: 32px;
          margin-left: 4px;
          cursor: pointer;
        }
      }

      &.static {
        input {
          min-width: 250px !important;
        }
      }

      &.m_less {
        margin: 0;
      }
    }

    .pickup_row {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;

      ._column {
        min-width: 150px;
        display: flex;
        flex-flow: column;
        margin: 0 12px;
        align-items: center;

        ._marco {
          text-align: center;
        }

        @media screen and (max-width: 576px) {
          min-width: 200px;

          label:not(._marco) {
            width: auto;
          }
        }
      }

      &._to_bottom {
        align-items: flex-end;
      }

      &._content_button {
        width: max-content;
        margin: 0 auto;
        position: relative;
      }
    }

    .field_icon {
      width: 100%;
      max-width: 250px;
      height: min-content;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        width: 18px;
        position: absolute;
        margin-right: 6px;
        pointer-events: none;
        color: var(--textColor);
      }

      .react-datepicker-popper {
        z-index: 18;
      }
    }

    .form_client {
      label {
        padding: 1rem 0.6rem 0;
        font-size: 0.9rem;
      }
    }
  }

  .boton_agregar {
    position: relative;
    display: block;
    margin: 30px 0 20px;
    text-align: right;

    .for_alert {
      position: relative;
      float: left;
    }

    &.center {
      margin: 30px auto 20px;
      text-align: center;

      .btn {
        margin: 0 1rem 0;
      }
    }

    @media screen and (max-width: 470px) {
      margin: 80px 0 20px;

      .for_alert {
        position: absolute;
        top: -62px;
        left: 0;
        width: min-content;
      }
    }
  }

  .check_individual {
    float: right;
    margin-right: 64px;

    input[type="checkbox"] {
      margin-right: 8px;
      pointer-events: none;
    }
  }

  .dropzone__img {
    h3 {
      margin: 12px 0 4px;
      font-size: 14px;
      font-weight: normal;

      svg {
        padding: 2px;
        float: right;
        cursor: pointer;
        transition: all 200ms;

        &:active {
          transform: scale(0.9);
        }
      }
    }

    .dropzone__h3 {
      display: block;
      width: 100%;
      height: 14px;
      padding: 8px 4px;
      font-size: 12px;
      line-height: 1.1;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      position: relative;
      cursor: auto;

      .dropzone__file-delete {
        position: absolute;
        top: 2px;
        right: 0;
        transition: all 0.3s;
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        color: rgb(60, 60, 60);
        font-size: 8px;
        line-height: 1;
        text-transform: uppercase;
        padding: 4px 7px;
        border: 1px solid rgb(60, 60, 60);
        border-radius: 6px;
        z-index: 22;
        opacity: 0;

        @media screen and (max-width: 576px) {
          padding: 6px 10px;
          font-size: 12px;
        }
      }

      span {
        display: block;
        width: 100%;
        margin: auto 0;
        position: relative;
        z-index: 20;
      }

      &:hover {
        .dropzone__img-delete,
        .dropzone__file-delete {
          opacity: 1;
        }
      }
    }
  }

  .dropzone--single {
    .dropzone__img {
      .dropzone__img-delete {
        padding: 4px 7px;
      }
    }

    .dropzone__loading {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 48px;
        height: 48px;
        margin: 0 auto;

        &.on_refresh {
          animation: refresh 1s infinite linear;
        }
      }

      i {
        position: absolute;
        font-size: 16px;
        color: rgb(60, 60, 60);

        &.progress {
          font-size: 16px;
          line-height: 1;
        }

        &.file {
          font-size: 14px;
          bottom: 0;
        }
      }
    }
  }
}

.new_container {
  display: block;
  width: 100%;
  padding: 20px 10px;
  background-color: var(--bkgColor);

  .new_features {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 18px !important;
    border-radius: 12px;
    border: 1px solid rgba(68, 47, 47, 0.224);

    legend {
      float: none;
      width: max-content;
      padding: 0 6px;
      margin: 0 4px;
      font-size: 0.8rem;
      line-height: inherit;
    }

    h5 {
      position: relative;
      width: 100%;
      padding-top: 6px;
      padding-left: 8px;

      span {
        padding: 4px 12px;
        border-radius: 8px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-shadow: 0 0 2px #1e1e1e, 0 0 1px #1e1e1e;
      }

      select {
        padding: 4px 12px;
        border-radius: 8px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-shadow: 0 0 2px #1e1e1e, 0 0 1px #1e1e1e;
      }

      svg {
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;

        @media screen and (max-width: 576px) {
          width: 2.3rem;
          height: 2.3rem;
        }
      }
    }

    .f_row {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      position: relative;
      padding-top: 24px;

      &._aux {
        margin-top: 36px;

        .features_options {
          width: 100px;
        }

        @media screen and (max-width: 1024px) {
          margin-top: 12px;
        }
      }

      ._in_row {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        position: relative;
        margin: 0 6px;

        &._t_vol {
          margin-left: 32px;

          @media screen and (max-width: 1024px) {
            margin-left: 6px;
          }
        }

        &._static_width {
          width: 250px;

          .input {
            width: 100%;
          }
        }

        input {
          padding: 10px 8px;
        }
      }

      h4 {
        margin: 0 18px;
        font-size: 14px;

        span {
          margin: 0 12px;
          color: rgb(100, 103, 119);
        }
      }

      .features_options {
        margin-top: 0;

        &._number {
          max-width: 60px;

          input[type="number"] {
            -moz-appearance: textfield;
            appearance: textfield;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }

        &._shorts {
          max-width: 160px;
        }

        &._static {
          width: 250px !important;
        }

        &._to_right {
          position: absolute;
          right: 8px;
        }

        label {
          color: rgb(100, 103, 119);
          white-space: nowrap;
        }

        textarea,
        input {
          width: 100%;
          margin: 4px 0 0;
          padding: 10px 8px;
          color: var(--textColor);
          font-size: 12px;
          border: 1px solid rgba(112, 112, 112, 0.224);
          border-radius: 4px;

          &::placeholder {
            text-transform: lowercase;
          }
        }

        textarea {
          height: 80%;
          resize: none;
        }

        .input_cant {
          width: 100%;
          display: flex;
          flex-direction: row;
        }

        ._with_unit {
          position: relative;
          width: 94px;
          max-width: 94px;
          height: 35px;
          padding-right: 35px;
        }
      }

      fieldset {
        &._in_row {
          .features_options {
            margin-top: 0;
          }
        }

        &._no_border {
          margin: 8px 0 !important;
          border: none !important;
        }
      }

      @media screen and (max-width: 576px) {
        flex-flow: column wrap;
        align-items: flex-start;

        fieldset {
          &._in_row {
            width: 100%;

            &._static_width,
            .input {
              width: 100%;
            }
          }
        }
      }
    }

    p {
      position: relative;
      width: 35px;
      height: 35px;
      margin: 0 0 0 -5px;
      padding: 6px 4px;
      font-size: 14px;
      color: rgb(149, 136, 134);
      border: none;
      border-left: 1px solid rgba(112, 112, 112, 0.224);
      border-radius: 0 4px 4px 0;
      text-align: center;
      background: transparent;

      /* &:active {
          transform: translate(2px, 2px)
          border: none
          border-top: 2px solid rgba(112, 112, 112, 0.4)
          border-left: 2px solid rgba(112, 112, 112, 0.4)
       } */
    }

    .first_row {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .pickup_column {
        width: 180px;
        margin: 8px 12px 0;

        .btn_tool {
          margin-top: -6px;
        }

        .input {
          input {
            padding: 6px 12px;
            font-size: 0.9rem;
          }
        }
      }

      @media screen and (min-width: 577px) {
        h5 {
          position: relative !important;
          width: min-content !important;
          margin: 0 8px 0 !important;

          select {
            padding: 4px 12px;
            font-size: 0.9rem;
          }

          samp {
            display: inline-block;
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            margin-bottom: 8px;
            margin-left: 8px;
          }
        }
      }

      @media screen and (max-width: 576px) {
        padding-bottom: 26px;
        border-bottom: 1px solid rgba(68, 47, 47, 0.224);

        h5 {
          position: relative !important;
          margin-top: 8px !important;

          select {
            width: 97%;
          }

          samp {
            display: none;
          }
        }

        .pickup_column {
          width: 100%;
          margin: 24px 8px 0;

          .input {
            input {
              padding: 6px 12px;
              font-size: 1rem !important;
            }
          }
        }
      }
    }

    ._radio_group {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 6px;
      padding-bottom: 8px;
      border-radius: 6px;

      ._close {
        position: absolute;
        width: 22px;
        height: 22px;
        top: -4px;
        right: 4px;
        cursor: pointer;
        color: #646777;
      }

      label {
        position: relative;
        display: inline-block;
        width: min-content;
        font-size: 0.9rem;
        padding: 4px 0;
        padding-left: 42px;
        margin-left: 4px;
        color: var(--textColor) !important;
        cursor: pointer;
        white-space: nowrap;
      }

      input[type="radio"] {
        position: absolute;
        top: 4px;
        left: 0;
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin: 0 12px;
        cursor: pointer;
      }

      input[type="checkbox"] {
        position: absolute;
        top: 4px;
        left: 0;
        width: 18px;
        min-width: 18px;
        height: 18px;
        margin: 0 12px;
        cursor: pointer;
      }
    }

    ._checkbox_group {
      position: absolute;
      top: 0.5rem;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 6px;
      padding-bottom: 8px;
      border-radius: 6px;

      label {
        position: relative;
        display: inline-block;
        width: min-content;
        font-size: 0.7rem;
        padding: 0.1rem 0;
        margin: 0 0.6rem;
        color: var(--textColor) !important;
        cursor: pointer;
        white-space: nowrap;
      }

      input[type="checkbox"] {
        position: relative;
        bottom: -0.14rem;
        width: 0.8rem;
        min-width: 0.8rem;
        height: 0.8rem;
        margin: 0 0.6rem;
        cursor: pointer;
      }

      @media screen and (max-width: 576px) {
        label {
          font-size: 0.9rem;
          padding: 0.4rem 0;
          margin: 0 0.9rem;
        }

        input[type="checkbox"] {
          width: 1.1rem;
          min-width: 1.1rem;
          height: 1.1rem;
        }
      }
    }
  }
}

.edit_package {
  position: absolute;
  padding: 10px 10px 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  cursor: auto;
  z-index: 91;

  h4 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 14px;

    svg {
      float: right;
      width: 28px !important;
      height: 28px !important;
      cursor: pointer;
    }
  }

  .f_row {
    max-width: 450px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    position: relative;

    &._aling_top {
      align-items: flex-start;
    }

    ._to_right {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      right: 12px;

      .btn {
        margin-bottom: 0 !important;
      }
    }

    .features_options {
      width: 100%;
      max-width: 200px;
      margin-top: 18px;
      margin-left: 12px;

      &._number {
        max-width: 60px;

        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      &._to_left {
        width: 100px;
        margin-left: 12px;
        position: relative;
      }

      label {
        color: rgb(100, 103, 119);
        white-space: nowrap;
      }

      textarea,
      input {
        width: 100%;
        max-width: 200px;
        margin: 4px 0 0;
        padding: 10px 8px;
        color: var(--textColor);
        font-size: 12px;
        border: 1px solid rgba(112, 112, 112, 0.224);
        border-radius: 4px;

        &::placeholder {
          text-transform: lowercase;
        }
      }

      textarea {
        height: 80%;
        resize: none;
      }

      .input_cant {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
    }
  }

  p {
    width: 35px;
    height: 36px;
    margin: 0 0 0 -2px;
    padding: 8px 4px;
    font-size: 14px;
    color: rgb(149, 136, 134);
    border: 1px solid rgba(112, 112, 112, 0.224);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: all 100ms;
    background: var(--bkgColor);
    box-shadow: 0 0 6px rgba(112, 112, 112, 0.4) inset;

    &:active {
      /* transform: translate(2px, 2px); */
      border: none;
      border-top: 2px solid rgba(112, 112, 112, 0.4);
      border-left: 2px solid rgba(112, 112, 112, 0.4);
    }
  }
}

.botones {
  display: flex;
  margin: 30px 0;
  margin-right: 40px;
  justify-content: right;
}

.pickup__collapse {
  position: relative;
  margin: 0 0 0 4px;
  display: block;

  & > button {
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: none;
    background: transparent;
    transition: all 0.3s;
    color: var(--textPrimary);
  }
}

.pickup__collapse-content {
  width: 120px;
  position: absolute;
  left: 0;
  bottom: 40px;
  transform: translateY(100%);
  box-shadow: 0 6px 12px 0 rgba(33, 36, 50, 0.4);
  background: var(--bkgColor);
  border-radius: 8px;
  z-index: 101;

  &.pickup__collapse-content--measure {
    padding: 10px 0;
    width: 120px;

    .pickup__measure-btn {
      padding: 4px 15px;
      width: 100%;
      border: none;
      background: transparent;
      cursor: pointer;
      text-align: left;
      font-size: 13px;
      line-height: 16px;
      transition: 0.3s;
      color: var(--textColor);

      &:hover {
        background: var(--colorHover);
      }
    }
  }

  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translate(-50%, 100%);
  }

  @media screen and (min-width: 520px) {
    width: 330px;
  }
}

.pickup__back {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }
}

.main_container {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 12px;
  background: var(--bkgColor);

  h5 {
    font-size: 13px;
    line-height: 1.4;

    &._collect {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
      color: rgb(255, 30, 30);
    }

    &.estado {
      margin-top: 12px;
      font-size: 14px;

      span {
        margin-left: 12px;
        padding: 4px 8px;
        border-radius: 6px;
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 2px black, 0 0 2px blue, 0 0 4px blue;
      }
    }
  }

  .t_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    ._columna {
      position: relative;
      width: 100%;
      padding-top: 10px;

      ._bar_code {
        display: block;
        text-align: right;

        svg {
          width: auto;
          height: 100px;
          margin: 0;

          g {
            fill: var(--textColor) !important;

            text {
              font-size: 18px !important;
              font-weight: bold !important;
              font-family: "Roboto", sans-serif !important;
            }
          }
        }

        @media screen and (max-width: 576px) {
          text-align: center;
        }
      }

      &:last-child {
        text-align: right;

        @media screen and (max-width: 576px) {
          text-align: center;
        }
      }
    }

    &.in_column {
      flex-direction: column;

      ._columna {
        ._bar_code {
          text-align: center;
        }
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  h4 {
    width: 100%;
    height: min-content;
    color: black;
    padding: 2px 8px;
    background-color: rgba(230, 230, 230, 255);
    border: 1px solid rgb(46, 46, 46);
    text-align: center;
    display: flex;
    justify-content: center;

    span {
      display: block;
      width: 50%;
      text-align: center !important;
      padding: 2px 8px;

      &:last-child {
        margin-left: -0.5px;
        border-left: 1px solid rgb(46, 46, 46);
      }
    }

    &._title_inside {
      border-left: none;
      border-right: none;
    }
  }

  ._tabla {
    width: 100%;
    display: flex;
    flex-direction: row;

    .columna {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-left: 1px solid rgb(46, 46, 46);
      border-right: 1px solid rgb(46, 46, 46);

      .notas {
        padding: 8px;
      }

      .izquierda {
        width: 250px;
        display: flex;
        flex-direction: column;
        text-align: right;

        ._th {
          padding: 2px 10px 2px 30px;
          font-weight: normal;
          color: var(--textColor);
          border-top: 1px solid rgba(112, 112, 112, 0.224);
        }
      }

      .derecha {
        width: 100%;
        display: flex;
        flex-direction: column;

        ._th {
          padding: 2px 0 2px 10px;
          font-weight: normal;
          border-left: 1px solid rgba(112, 112, 112, 0.224);
          border-top: 1px solid rgba(112, 112, 112, 0.224);
          color: var(--textColor);
        }
      }

      .cont_info {
        width: 100%;
        padding: 6px 0 30px 4px;
        font-size: 0.9rem;
        font-weight: normal;
        overflow: hidden;

        h3 {
          font-size: 16px;
          font-weight: 600;
          margin-top: 4px;
        }

        p {
          width: 100%;
          padding-left: 12px;
          margin-bottom: -10px;
          line-height: 1.3;

          &.observacion {
            margin-top: 8px;
            margin-bottom: 0;

            span {
              font-size: 12px;
              font-weight: normal;
              border-radius: 6px;
              padding: 2px 8px;
              margin-right: 6px;
              color: rgb(255, 255, 255);
              text-shadow: 1px 1px 2px black, 0 0 2px blue, 0 0 4px blue;
            }

            samp {
              display: inline-block;
              font-size: 12px;
              line-height: 1;
              font-family: Arial, Helvetica, sans-serif;
              padding: 2px 6px;
              margin: 6px 0 6px;
              margin-left: 12px;
              border-radius: 6px;
              border: 1px solid rgb(100, 103, 119);

              @media screen and (max-width: 576px) {
                margin-left: 0;
              }
            }

            b {
              font-size: 12px;
            }

            i {
              display: inline-block;
              padding: 4px 0 8px;
              white-space: pre-line;

              &._dv_type {
                font-size: 12px;
                color: #ffffff;
                background-color: rgb(80, 150, 0);
                border-radius: 6px;
                padding: 2px 6px 2px;
                margin-right: 6px;
              }
            }
          }

          span.marco {
            display: inline-block;
            padding: 0.08rem 0.6rem;
            margin-right: 0.3rem;
            margin-bottom: 0.3rem;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            font-weight: lighter;
            border-radius: 6px;
            border: 1px solid var(--colorInput);
          }
        }

        @media screen and (max-width: 576px) {
          padding: 8px 6px;

          p {
            padding: 0;
            margin: 0;
          }
        }
      }

      &.datos {
        width: 100%;
        height: 90px;
        display: flex;
        flex-direction: row;
      }

      &:first-child {
        border-right: none;

        &._m_right {
          border-right: 1px solid rgb(46, 46, 46);
        }
      }
    }

    .fila {
      width: 100%;
      height: min-content;
      padding: 2px 8px;
      border-left: 1px solid rgb(46, 46, 46);
      border-right: 1px solid rgb(46, 46, 46);
      border-bottom: 1px solid rgb(46, 46, 46);
      text-align: center;
      display: flex;
      justify-content: center;

      .notas {
        padding: 8px;
      }

      .izquierda {
        width: 250px;
        display: flex;
        flex-direction: column;
        text-align: right;

        ._th {
          padding: 2px 10px 2px 30px;
          font-weight: normal;
          color: var(--textColor);
          border-top: 1px solid rgba(112, 112, 112, 0.224);
        }
      }

      .derecha {
        width: 100%;
        display: flex;
        flex-direction: column;

        ._th {
          padding: 2px 0 2px 10px;
          font-weight: normal;
          border-left: 1px solid rgba(112, 112, 112, 0.224);
          border-top: 1px solid rgba(112, 112, 112, 0.224);
          color: var(--textColor);
        }
      }
    }
  }

  .t_resumen {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    overflow: hidden;

    tbody {
      width: 100%;
      border: 1px solid rgb(46, 46, 46);
    }

    hr {
      width: 100%;
      margin: 4px 0;
    }

    tr {
      width: 100%;

      th,
      td {
        border-right: 1px solid rgb(46, 46, 46);
        overflow: hidden;

        &:nth-child(1) {
          min-width: 100px;
        }

        &:nth-child(2) {
          min-width: 110px;
          width: 100%;
        }

        &:nth-last-child(3) {
          min-width: 100px;
        }

        &:nth-last-child(2) {
          min-width: 100px;
        }

        &:nth-last-child(1) {
          min-width: 100px;
          border-right: none;
        }

        @media screen and (max-width: 720px) {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6) {
            width: auto;
            min-width: fit-content;
          }
        }

        @media screen and (max-width: 480px) {
          &:nth-child(1) {
            /*  display: none */
            width: 0;
            max-width: 0;
            border: none;
          }

          &:nth-child(2) {
            p {
              padding-left: 2px;
              padding-right: 4px;
            }
          }

          &:nth-child(3) {
            width: 100%;
            min-width: 80px;
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 60px;
            min-width: 50px;
          }
        }
      }

      th {
        height: 30px;
        vertical-align: top;
        margin: 0;
        padding: 0;
        overflow: hidden;

        ._th {
          width: 100%;
          padding: 0 6px;
          color: black;
          background-color: rgba(230, 230, 230, 255);
          border-bottom: 1px solid rgb(46, 46, 46);
          text-align: center;
          line-height: 2;
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      td {
        a {
          color: rgb(32, 0, 176);
          font-weight: bold;
          text-decoration: none;
        }

        p {
          display: block;
          width: 100%;
          padding-left: 8px;

          samp {
            display: inline-block;
            min-width: 30px;
            margin-right: 8px;
          }

          span {
            float: right;
            padding-right: 8px;
          }
        }
      }
    }
  }

  .t_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(46, 46, 46);
    border-top: none;

    .observaciones {
      width: 100%;
      padding-right: 80px;
      overflow: hidden;

      p {
        position: relative;
        padding: 65px 0 18px 6px;

        span {
          position: absolute;
          margin-top: 8px;
          margin-left: 6px;
        }
      }
    }

    .peso {
      width: min-content;
      display: flex;
      flex-direction: row;
      justify-content: right;
      border-left: 1px solid rgb(46, 46, 46);

      .colunmas {
        width: 160px;
        border-right: 1px solid rgb(46, 46, 46);

        ._th {
          width: 100%;
          padding: 0 6px;
          color: black;
          background-color: rgba(230, 230, 230, 255);
          border-bottom: 1px solid rgb(46, 46, 46);
          text-align: center;
          line-height: 2;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }

        p,
        a {
          padding: 0 10px 0;
        }

        &:first-child {
          max-width: 100px;
        }

        &:last-child {
          border-right: none;
        }

        @media screen and (max-width: 720px) {
          width: auto;
        }
      }
    }
  }

  &.rorulo_print {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100mm;
    max-width: 100mm;
    height: 150mm;
    overflow: hidden;

    ._columna {
      display: flex;
      flex-direction: column;

      &._margen {
        padding: 0 1rem;
      }

      &._code_bar {
        border-top: 1px solid var(--colorInput);
      }

      &._body {
        height: 100mm;
        /*  max-height: 60mm */
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          text-align: center;
          font-size: 0.8rem;

          b {
            font-size: 1.1rem;
          }
        }

        h3 {
          display: block;
          width: 100%;
          margin: 0.5rem 0 0.3rem;
          font-size: 1.1rem;
          line-height: 1.45;
          text-align: center;
          text-overflow: ellipsis;

          span.marco {
            display: inline-block;
            padding: 0 0.7rem;
            margin-bottom: 0.4rem;
            font-size: 1rem;
            font-weight: normal;
            border-radius: 6px;
            border: 1px solid var(--colorInput);
          }
        }
      }

      h5 {
        margin: 0.2rem 0;
        font-size: 0.8rem;
        font-weight: lighter;
        text-align: left;

        b {
          font-weight: bold;
          font-size: 0.9rem;

          &.title {
            font-size: 1rem;
          }
        }

        span {
          font-weight: normal;
        }
      }
    }

    ._fila_tienda {
      width: 100%;
      /*    height: 26mm */
      /*    max-height: 26mm */
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;

      img.logo_tienda {
        margin: 1rem;
        width: 20mm;
        min-width: 20mm;
        height: 20mm;
        object-fit: contain;
        border-radius: 0.3rem;
      }
    }

    ._fila_redes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 12mm;
      padding: 0 0.3rem 0.2rem;

      i {
        margin: 0 0 0.1rem;
        padding: 0 0.5rem;
        font-size: 0.7rem;
        font-weight: lighter;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--textColor);

        svg {
          margin-top: -0.1rem;
          width: 1rem;
          height: 1rem;
        }

        span {
          font-weight: normal;
          font-size: 0.8rem;
        }
      }
    }

    ._fila_footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30mm;
      max-height: 30mm;
      padding: 0 1rem;
      padding-top: 0.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0 0 8px 8px;
      border-top: 1px solid var(--colorInput);
      background: var(--bkgColor);

      h5 {
        line-height: 1.2;
      }

      svg.logo_bts {
        display: block;
        margin: -14px -8px 0 0;
        padding: 0;
        width: 32mm;
        min-width: 32mm;
        height: 32mm;
      }
    }

    svg.logo_bts {
      margin: 0 auto -4px;

      g {
        fill: var(--textColor);
      }
    }

    @media print {
      width: 100mm;
      max-width: 100mm;
      height: 150mm;
      margin-top: 0.3rem;
      border: 1px solid var(--color-print);
      border-radius: 8px;
      color: var(--color-print);
      background-color: #ffffff;
      overflow: hidden;

      ._columna {
        background-color: #ffffff;
        overflow: hidden;
        border: none;

        &._code_bar {
          border-top: 1px solid rgba(var(--color-print), 0.3);
        }

        &._body {
          h3 {
            span.marco {
              border: 1px solid var(--color-print);
            }
          }
        }
      }

      ._fila_tienda {
        background-color: #ffffff;
      }

      ._fila_redes {
        max-height: 12mm;
        overflow: hidden;
        background-color: #ffffff;
      }

      ._fila_footer {
        border-radius: 0 0 8px 8px;
        border-top: 1px solid rgba(var(--color-print), 0.5);
        background-color: #ffffff;

        svg.logo_bts {
          g {
            fill: #000000 !important;
            /*  fill: #172940 !important */
          }
        }
      }

      ._bar_code svg,
      svg.logo_bts {
        g {
          fill: var(--color-print);
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    padding: 10px 8px;
  }
}

.contain_files {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  img {
    width: 150px;
    height: auto;
    margin: 2rem 18px 8px;
    object-fit: contain;
    cursor: pointer;
  }

  ._evidence {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: auto;
    margin: 0 18px 8px;

    i {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      text-align: center;
      font-weight: bold;
      color: var(--textPrimary);

      &._note {
        font-size: 0.8rem;
        font-weight: lighter;
      }
    }

    img {
      margin: 2rem 0 0;
    }
  }

  @media screen and (max-width: 576px) {
    img {
      width: 100px;
      margin: 2rem 4px 8px;
    }

    ._evidence {
      width: 135px;
      margin: 0 8px 8px;

      i {
        font-size: 0.8rem;

        &._note {
          font-size: 0.7rem;
        }
      }
    }
  }
}

label,
textarea,
input {
  &::placeholder {
    color: var(--textColor);
    opacity: 0.5;
  }
}

@media screen and (max-width: 576px) {
  label,
  textarea,
  input {
    font-size: 1rem;
  }
}
