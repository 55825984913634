.main_container {
  position: relative;

  ._nota {
    position: absolute;
    width: 96%;
    margin-top: -110px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      width: 60%;
      font-size: 8px;
      text-align: center;
    }
  }

  .t_footer {
    .observaciones {
      position: relative;

      &._padding_right_8 {
        padding-right: 8px !important;
      }

      p {
        position: relative;

        strong {
          position: absolute;
          right: 0;
          font-size: 16px;
          margin-top: -40px;
          white-space: nowrap;
        }
      }
    }
  }

  .options_generator {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: 1rem;
      padding: 4px 20px;
      text-align: center;
      color: var(--textColor);
    }

    ._alerta {
      margin: 24px 0 12px;
      font-weight: bold;
      font-style: italic;
      color: var(--textRed);
    }

    .input_generator {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 12px 0;

      input {
        width: 100%;
        max-width: 180px;
        padding: 8px 8px 6px;
        font-size: 1rem;
        border-radius: 4px;
        outline: none;
        border: 1px solid var(--colorInput);
        background: var(--bkgColor);
        color: var(--textColor);

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      /*  -moz-appearance: textfield */
      /*  appearance: textfield */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .options_output {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.content_generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px;
  margin: 0;

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 12px 0 0;
  }
}

.code_generator {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0;
  padding: 0;

  ._code {
    padding: 0;
    margin: 0 -26px;
    transform: scale(0.7);

    &._margin {
      margin: 14px 12px;
    }
  }
}
