.cargo_release {
  padding: 10px 20px;
  background-color: rgb(255, 255, 255);

  .portada {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(128, 211, 248, 255);

    .logo_ {
      width: 15%;
      margin-right: 150px;

      img {
        width: 150px;
      }
    }

    .datos_ {
      width: 40%;
      margin-right: 50px;
      flex-wrap: nowrap;

      p {
        width: 100%;
        margin-top: 24px;
      }
    }

    .cod_barra {
      width: 40%;
      text-align: right;
      padding-left: 80px;
      flex-wrap: nowrap;

      h2 {
        font-size: 25px;
        font-weight: bold;
        color: rgba(143, 203, 246, 255);
      }

      svg {
        width: 150px;
        height: 50px;
        background-color: darkcyan;
      }

      p {
        font-weight: bold;
        font-size: 16px;
      }

      h3 {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .contenido {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    .columna_1 {
      width: 40%;

      h5 {
        font-weight: bold;
      }
    }

    .columna_2 {
      width: 50%;
      p {
        margin-bottom: 25px;
      }
    }

    .columna_3 {
      padding: 8px;
      width: 50%;
      background-color: rgba(230, 230, 230, 255);
    }
  }

  .final {
    width: 100%;
    margin-top: 20px;

    ._pestañas {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      p {
        margin: 0 2px;
        padding: 4px 0;
        text-align: center;
        background-color: rgba(230, 230, 230, 255);
      }
    }

    .filas_ {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0 2px;
      padding: 4px 0;
      text-align: center;

      p {
        text-align: center;

        &:first-child {
          margin: 10px 0 0 0;
        }
      }
    }

    .fila1 {
      width: 40px;
    }

    .fila2 {
      width: 100px;
    }

    .fila3 {
      width: 160px;
    }

    .fila4 {
      width: 270px;
    }
  }
}
