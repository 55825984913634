.cont_principal {
  padding-bottom: 1px;

  ._titulo {
    width: 100%;
    margin: 100px 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.3;
    white-space: pre-line;
    overflow: hidden;
  }

  ._button {
    width: max-content;
    margin: 18px auto 0;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1;
    border-radius: 6px;
    cursor: pointer;
    color: var(--textColor);
    border: 1px solid var(--textColor);

    svg {
      margin-left: 6px;
    }

    &._width_static {
      width: 100%;
    }
  }

  ._img_full {
    height: 100%;
    object-fit: scale-down;
    max-height: calc(100vh - 80px);
  }

  .btn_lazy_load {
    display: block;
    width: max-content;
    padding: 0.4rem 2rem;
    margin: 0.9rem auto 0;
    font-size: 1.2rem;
    border-radius: 12px;
    color: var(--textColor);
    border: 1px solid var(--textColor);
    background: var(--bkgColor);
    cursor: pointer;

    svg {
      margin-top: -4px;
      width: 1.2rem;
      height: 1.2rem;
      transform: scale(1.7);
    }

    @media screen and (max-width: 576px) {
      width: 90%;
      padding: 0.5rem 2rem;
      margin: 0.5rem auto 1rem;
    }
  }

  .h4_lazy_load {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    white-space: pre-line;
  }
}

.table {
  width: 100%;
  max-width: 100vw;
  border-collapse: initial;
  border-radius: 12px;
  border-spacing: 0;
  overflow: hidden;
  background: var(--bkgColor);
  border: solid 1px var(--bkgBlue);

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bkgColor);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid var(--colorThumb);
    background-color: var(--colorThumb);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--textColor);
  }

  th,
  td {
    padding: 0 0 0 4px;

    &._to_right {
      text-align: right;
      padding-right: 0.9rem !important;
    }
  }

  th {
    border-right: 1px solid var(--bkgBlue);

    &:last-child {
      border-right: 0 solid;
      border-right-color: inherit;
    }
  }

  td {
    border-top: 1px solid var(--bkgBlue);
    border-right: 1px solid var(--bkgBlue);

    &:last-child {
      padding: 0 !important;
      border-right: 0 solid;
      border-right-color: inherit;
    }

    &:nth-last-child(2) {
      border-right: 0 solid;
      border-right-color: inherit;
    }
  }

  .table_title {
    width: 100%;
    color: rgb(109, 106, 106);
    font-size: 14px;

    th {
      position: relative;
      padding: 4px;

      &:first-child {
        padding-left: 10px;
      }

      svg:not(.to_filter) {
        width: 26px;
        height: 26px;
        margin-left: -8px;
        margin-right: 6px;
        cursor: pointer;
      }

      .to_filter {
        position: relative;
        width: 26px;
        height: 26px;
        padding: 4px;
        margin: 0 6px 0 0;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 576px) {
      font-size: 16px;

      th {
        max-width: 20vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(3) {
          max-width: 60px;
        }
      }
    }
  }

  .table_row {
    width: 100%;
    font-size: 0.8rem;
    cursor: pointer;
    color: var(--textGray);

    td {
      position: relative;
      padding: 4px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        padding-right: 12px;
        text-align: left;
      }

      div._states {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      span._states {
        padding: 2px 8px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 0.9rem;
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 2px black, 0 0 2px blue, 0 0 4px blue;
      }

      div._states b {
        font-size: 1.2rem;
      }

      div._states strong {
        margin: 12px 0 0;
        padding: 0;
        font-size: 0.9rem;
        line-height: 1;
        color: var(--textGray);
      }

      div._states strong i,
      i._states {
        margin: 0;
        padding: 0;
        float: right;
        font-size: 0.7rem;
        font-weight: lighter;
        color: var(--textGray);
      }

      p._states {
        width: 100%;
        font-size: 0.8rem;
        font-weight: normal;
        white-space: break-spaces;
        color: var(--textGray);

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: bold;

          b {
            padding: 0 0.1rem 0 0.9rem;
            font-size: 0.8rem;
            font-weight: normal;
            line-height: 1.9;
          }
        }
      }

      ._icon_load {
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        width: 1.2rem;
        height: 1.2rem;
      }

      @media screen and (max-width: 576px) {
        padding: 8px 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        /* &:first-child {
            max-width: 100px;
         } */
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: 6px;

      @media screen and (max-width: 576px) {
        width: 26px;
        height: 26px;
      }
    }

    &:hover {
      color: var(--colorHoverRow) !important;
      background-color: var(--bkgHoverRow) !important;

      td {
        color: rgb(255, 255, 255) !important;
      }
    }

    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }

  .bkg_blue {
    background-color: var(--bkgBlue);
    border-bottom: 1px solid var(--bkgColor);
  }

  .bkg_yellow {
    background-color: rgba(250, 221, 60, 0.8);
    border-bottom: 1px solid var(--bkgColor);

    td {
      color: var(--textColor);
    }
  }

  .bkg_orange {
    background-color: rgba(255, 120, 0, 0.8);
    border-bottom: 1px solid var(--bkgColor);

    td {
      color: #fff !important;
    }
  }

  .bkg_red {
    background-color: #f5320d;
    border-bottom: 1px solid var(--bkgColor);

    td {
      color: #fff !important;
    }
  }

  .bkg_select {
    color: rgb(255, 255, 255);
    background-color: rgb(80, 133, 194);

    td {
      color: rgb(255, 255, 255) !important;
    }
  }

  ._to_clic {
    cursor: pointer;

    &._resalta {
      background-color: rgb(255, 255, 40);

      td {
        color: rgb(70, 70, 70) !important;
        font-weight: bold;
      }
    }
  }
}
